/**
 * URLParser
 */
import TypeUtil from './type-util';
import CommonUtil from './common-util';

/**
 * URLParser 객체 정의
 */
const URLParser = function (url) {
  if (url) {
    this.url = url;
    this.tokenized = false;
  } else {
    throw new Error('url is necessary!');
  }
};

/**
 * URL을 분석하여 각 구성요소로 분리해낸다.
 */
URLParser.prototype.tokenize = function () {
  const urlTokens = {};
  if (this.url) {
    this.url = this.url.replace(
      /^(?:(?:((?:https{0,1}:){0,1})\/\/){0,1}((?:[^:\\/?#]*)(?::\d+){0,1})){0,1}([^?#]*)((?:\?[^#]*){0,1})((?:#.*){0,1})$/gi,
      function (match, protocol, host, pathname, search, hash, index, orgString) {
        urlTokens.protocol = protocol || '';
        if (protocol && protocol.includes(':')) {
          urlTokens.protocolValue = protocol.substring(0, protocol.indexOf(':'));
        }
        urlTokens.host = host || '';
        if (urlTokens.protocol && urlTokens.host) {
          urlTokens.origin = urlTokens.protocol + '//' + urlTokens.host;
        }
        const hostTokens = urlTokens.host.split(':');
        urlTokens.hostname = hostTokens[0];
        if (hostTokens[1]) {
          urlTokens.port = hostTokens[1];
        }
        urlTokens.pathname = pathname || '';
        urlTokens.search = search || '';
        if (search && search.includes('?')) {
          urlTokens.searchValue = search.substring(search.indexOf('?') + 1);
        }
        urlTokens.hash = hash || '';
        if (hash && hash.includes('#')) {
          urlTokens.hashValue = hash.substring(hash.indexOf('#') + 1);
        }
        return orgString;
      },
    );
  }
  this.protocol = urlTokens.protocol ? urlTokens.protocol : '';
  this.protocolValue = urlTokens.protocolValue ? urlTokens.protocolValue : '';
  this.host = urlTokens.host ? urlTokens.host : '';
  this.origin = urlTokens.origin ? urlTokens.origin : '';
  this.hostname = urlTokens.hostname ? urlTokens.hostname : '';
  this.port = urlTokens.port ? urlTokens.port : '';
  this.pathname = urlTokens.pathname ? urlTokens.pathname : '';
  this.search = urlTokens.search ? urlTokens.search : '';
  this.searchValue = urlTokens.searchValue ? urlTokens.searchValue : '';
  this.hash = urlTokens.hash ? urlTokens.hash : '';
  this.hashValue = urlTokens.hashValue ? urlTokens.hashValue : '';
  this.tokenized = true;

  return this;
};

/**
 * 현재 객체내에 설정된 URL 구성정보들을 가지고 URL을 문자열로 생성한다.
 */
URLParser.prototype.toString = function () {
  if (this.tokenized) {
    return (
      (this.host ? (this.protocol ? this.protocol : '') + '//' + this.host : '') +
      this.pathname +
      this.search +
      this.hash
    );
  } else {
    return this.url;
  }
};

/**
 * URL에 get방식 query 파라미터를 붙인다. (단건처리)
 * @param key {string} 파라미터 key
 * @param value {string} 파라미터 value
 */
URLParser.prototype.addParam = function (key, value) {
  const _paramObject = {};
  _paramObject[key] = value;
  return this.addParamObject(_paramObject);
};

/**
 * URL에 get방식 query 파라미터를 붙인다. (다건처리)
 * @param paramObject {object} 파라미터 object - object 내의 key-value 쌍으로 파라미터를 붙인다.
 * 			{
 * 				key1: value1,
 * 				key2: value2,
 * 				...
 * 			}
 */
URLParser.prototype.addParamObject = function (paramObject) {
  // tokenize 호출이 되어 있지 않으면 선호출 시켜서 URL 구성요소 분리해야 한다.
  if (!this.tokenized) {
    this.tokenize();
  }

  if (TypeUtil.isPlainObject(paramObject)) {
    for (const key in paramObject) {
      const value = paramObject[key];
      if (key && value) {
        this.search += (this.search ? '&' : '?') + (key + '=' + encodeURIComponent(value));
      }
    }
  }
  return this;
};

/**
 * URL에 get방식 query 파라미터를 붙인다. (다건처리)
 * @param paramArray {array} 파라미터 key,value object array - object 내에 key값, value값을 각각 정의하여 사용.
 * 			[
 * 				{
 * 					key: {string} 파라미터 key1
 * 					value: {string} 파라미터 value1
 * 				},
 * 				{
 * 					key: {string} 파라미터 key2
 * 					value: {string} 파라미터 value2
 * 				}, ...
 * 			]
 */
URLParser.prototype.addParamArray = function (paramArray) {
  // tokenize 호출이 되어 있지 않으면 선호출 시켜서 URL 구성요소 분리해야 한다.
  if (!this.tokenized) {
    this.tokenize();
  }

  if (paramArray && paramArray.length > 0) {
    for (let i = 0; i < paramArray.length; i++) {
      const key = paramArray[i].key;
      const value = paramArray[i].value;
      if (key && value) {
        this.search += (this.search ? '&' : '?') + (key + '=' + encodeURIComponent(value));
      }
    }
    //			scard.log.debug("		>>>>>>>>>> Parameter appended url: " + this.toString());
  }
  return this;
};

/**
 * 특정 URL이 대상 (BASE) URL 목록의 도메인에 해당하는 URL인지를 체크한다.
 *
 * @param {Array} urls 대상 (BASE) URL 목록
 * @returns
 */
URLParser.prototype.isIncluded = function (urls) {
  // tokenize 호출이 되어 있지 않으면 선호출 시켜서 URL 구성요소 분리해야 한다.
  !this.tokenized && this.tokenize();
  return this.host && urls?.some(url => url.includes(this.host));
};

export default URLParser;

/**
 * PFM (모니모) URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isPfmUrl = url => {
  const parsedUrl = new URLParser(url).tokenize();
  return !parsedUrl.host || parsedUrl.isIncluded([process.env.BROWSER_BASE_URL, process.env.PFM_BASE_URL]);
};

/**
 * WCMS URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isWcmsUrl = url => {
  return new URLParser(url).tokenize().isIncluded([process.env.WCMS_BASE_URL]);
};

/**
 * 현재 환경에서의 mdp isp Url을 가져온다.
 */
export const getMdpIspUrl = () => {
  return process.env.MDP_ISP_URL;
};

/**
 * 현재 환경에서의 hpp baseUrl을 가져온다.
 */
export const getHppBaseUrl = () => {
  let hppBaseUrl = '';

  // 로컬or개발계일 때, 도메인 분기 처리
  if (CommonUtil.isDev() || CommonUtil.isTest()) {
    if (CommonUtil.isInnerAp()) {
      hppBaseUrl = process.env.HPP_BASE_URL;
    } else if (CommonUtil.isInnerApSsl()) {
      hppBaseUrl = process.env.HPP_BASE_SSL_URL;
    } else if (CommonUtil.isExternal()) {
      hppBaseUrl = process.env.HPP_BASE_EXTERNAL_URL;
    }
    // agent에 값이 없다면, 로컬로 간주
    // 현재 https로 접속중인 경우
    else if (location.protocol.includes('https')) {
      hppBaseUrl = process.env.HPP_BASE_SSL_URL;
    } else {
      hppBaseUrl = process.env.HPP_BASE_URL;
    }
  }
  // 그 외의 경우,
  else {
    hppBaseUrl = process.env.HPP_BASE_URL;
  }

  return hppBaseUrl;
};

/**
 * HPP 카드 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isHppUrl = url => {
  return new URLParser(url).tokenize().isIncluded([getHppBaseUrl()]);
};

/**
 * SLF 생명_홈페이지 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSlfHmpgUrl = url => {
  return new URLParser(url).tokenize().isIncluded([process.env.SLF_HMPG_BASE_URL]);
};

/**
 * SLF 생명_신규 홈페이지 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSlfNwhpUrl = url => {
  return new URLParser(url).tokenize().isIncluded([process.env.SLF_NWHP_BASE_URL]);
};

/**
 * SLF 생명_다이렉트 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSlfDirctUrl = url => {
  return new URLParser(url).tokenize().isIncluded([process.env.SLF_DIRCT_BASE_URL]);
};

/**
 * SSF 화재_홈페이지 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSsfHmpgUrl = url => {
  return new URLParser(url).tokenize().isIncluded([process.env.SSF_HMPG_BASE_URL]);
};

/**
 * SSF 화재_다이렉트 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSsfDirctUrl = url => {
  return new URLParser(url).tokenize().isIncluded([process.env.SSF_DIRCT_BASE_URL]);
};

/**
 * SSS 증권 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSssUrl = url => {
  return new URLParser(url).tokenize().isIncluded([process.env.SSS_BASE_URL]);
};

/**
 * 모니모 관계사 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isMnmAflcmpUrl = url => {
  return (
    isHppUrl(url) ||
    isSlfHmpgUrl(url) ||
    isSlfNwhpUrl(url) ||
    isSlfDirctUrl(url) ||
    isSsfHmpgUrl(url) ||
    isSsfDirctUrl(url) ||
    isSssUrl(url)
  );
};

/**
 * URL을 특정 domain이 붙은 URL로 변환한다.
 *    경로(path)만 포함한 URL인 경우, 대상 도메인이 앞에 붙고,
 *    도메인이 이미 포함된 URL인 경우, 대상 도메인으로 변경이 되고, 나머지 URL 구성 요소는 유지한다.
 *    변경할 대상 도메인 값이 프로토콜을 포함하면 해당 프로토콜을 따라가고, 없다면 브라우저의 현재 location의 프로토콜을 따라간다.
 * @param {String} url 도메인을 변경할 URL
 * @param {String} domain 변경할 대상 도메인
 * @returns domain이 치환된 URL
 */
export const replaceDomain = (url, domain) => {
  const parsedUrl = new URLParser(url).tokenize();
  const parsedDomain = new URLParser(domain).tokenize();

  parsedUrl.protocol = parsedDomain.protocol || window?.location?.protocol;
  parsedUrl.protocolValue =
    parsedDomain.protocolValue || parsedUrl?.protocol?.substring(0, parsedUrl.protocol?.indexOf(':'));
  parsedUrl.host = parsedDomain.host;
  parsedUrl.origin = parsedDomain.origin;
  parsedUrl.hostname = parsedDomain.hostname;
  parsedUrl.port = parsedDomain.port;

  return parsedUrl.toString();
};

/**
 * PFM (모니모) URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toPfmUrl = url => {
  return replaceDomain(url, process.env.PFM_BASE_URL);
};

/**
 * WCMS URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toWcmsUrl = url => {
  return replaceDomain(url, process.env.WCMS_BASE_URL);
};

/**
 * WCMS IMAGE URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toWcmsImageUrl = url => {
  return replaceDomain(url, process.env.WCMS_IMAGE_BASE_URL);
};

/**
 * HPP 카드 URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toHppUrl = url => {
  return replaceDomain(url, getHppBaseUrl());
};

/**
 * SLF 생명_홈페이지 URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toSlfHmpgUrl = url => {
  return replaceDomain(url, process.env.SLF_HMPG_BASE_URL);
};

/**
 * SLF 생명_신규 홈페이지 URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toSlfNwhpUrl = url => {
  return replaceDomain(url, process.env.SLF_NWHP_BASE_URL);
};

/**
 * SLF 생명_다이렉트 URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toSlfDirctUrl = url => {
  return replaceDomain(url, process.env.SLF_DIRCT_BASE_URL);
};

/**
 * SSF 화재_홈페이지 URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toSsfHmpgUrl = url => {
  return replaceDomain(url, process.env.SSF_HMPG_BASE_URL);
};

/**
 * SSF 화재_다이렉트 URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toSsfDirctUrl = url => {
  return replaceDomain(url, process.env.SSF_DIRCT_BASE_URL);
};

/**
 * SSS 증권 URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toSssUrl = url => {
  return replaceDomain(url, process.env.SSS_BASE_URL);
};

/**
 * URL이 어떤 관계사 URL인지를 체크하여 해당 관계사의 모니모 관계사 코드를 가져온다.
 * @param {String} url URL
 * @returns 모니모관계사코드 (HPP(카드)|SLF(생명)|SSF(화재)|SSS(증권)) 또는 ''
 */
export const toMnmAflcmpC = url => {
  let URL_PREFIX = '';
  if (isMnmAflcmpUrl(url)) {
    Object.entries(window?.$nuxt?.context?.env).forEach(([key, value]) => {
      if (key.endsWith('URL') && new URLParser(url).tokenize().isIncluded([value])) {
        URL_PREFIX = key.substr(0, key.indexOf('_'));
      }
    });
  }
  return URL_PREFIX;
};

/**
 * (성인 및 주니어 더치페이 공유 기능) 애드브릭스 링크를 생성한다.
 * @param {String} cmpId 캠페인ID
 * @param {String} url 랜딩할 모니모 화면 URL
 * @param {String} query query param
 * @returns 애드브릭스 링크
 */
export const createDPAdbrixLink = (cmpId = '', ad = '', agency = '', keyword = '', url = '', query = '') => {
  const queryParam = `${query}`;
  const advParam = `&adv_campaign=mnm&adv_ad=${ad}&adv_agency=${agency}&adv_keyword=${keyword}`;
  return `https://y5zd2pF2kEaD4sFEqXQj5A.adtouch.adbrix.io/api/v1/click/${cmpId}?deeplink_custom_path=monimoapp${encodeURIComponent(
    '://adbrix?url=' + toPfmUrl(url),
  )}${queryParam}${advParam}`;
};

/**
 * 애드브릭스 링크를 생성한다.
 * @param {String} cmpId 캠페인ID
 * @param {String} url 랜딩할 모니모 화면 URL
 * @param {String} query query param
 * @param {String} advParam 마케팅 수집용 param
 * @returns 애드브릭스 링크
 */
export const createAdbrixLink = (cmpId = '', url = '', query = '', advParam = '') => {
  const queryParam = `%3Fparam%3D${query}`;
  return `https://y5zd2pF2kEaD4sFEqXQj5A.adtouch.adbrix.io/api/v1/click/${cmpId}?deeplink_custom_path=monimoapp${encodeURIComponent(
    '://adbrix?url=' + toPfmUrl(url),
  )}${queryParam}${advParam}`;
};

/**
 * MCMS URL 로 도메인을 변환하여 가져온다.
 * @param {String} url 도메인을 변경할 URL
 * @returns domain이 치환된 URL
 */
export const toMcmsUrl = url => {
  return replaceDomain(url, process.env.MCMS_BASE_URL);
};

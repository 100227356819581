/**
 * 상수 정의 모듈
 */

/** DEBUG 모드 로그 출력 여부 */
export const IS_DEBUG = process.env.NODE_ENV !== 'production';

/** 로컬(DEV) 여부 */
export const IS_DEV = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'devlocal';
export const IS_TEST = process.env.NODE_ENV === 'test';
export const IS_STAGE = process.env.NODE_ENV === 'staging';
export const IS_PROD = process.env.NODE_ENV === 'production';

export const PFM_BASE_URL = {
  development: window?.location?.origin || 'http://127.0.0.1:3000',
  devlocal: window?.location?.origin || 'http://127.0.0.1:3000',
  test: 'http://devwww.monimo.com',
  staging: 'http://stgwww.monimo.com',
  production: 'https://www.monimo.com',
};

/** DeepLink 관련 */
export const DEEPLINK_BASE_URL = 'https://monimo.page.link/';
export const DEEPLINK_LINK_URL = 'https://monimolink';
export const DEEPLINK_LINK_APN = 'net.ib.android.smcard';
export const DEEPLINK_LINK_ISI = '379577046';
export const DEEPLINK_LINK_IBI = 'com.samsungCard.samsungCard';

/** 모바일OS구분코드: [01] 안드로이드 / [02] iOS / [03] 기타 */
export const MBL_OS_DV_C = { ANDROID: '01', IOS: '02', OTHER: '03' };

/** 네이티브 인앱 여부를 체크할 agent 문자열 */
export const NATIVE_USERAGENT_STRING = 'mblSappYn=Y';

/** 기본 핸들러 이름 */
export const DEFAULT_HANDLER_NAME = 'PFMAppInterface';

export const CONSOLE_STYLE1 = [
  'background-color: red',
  'border: 1px solid',
  'color: white',
  'display: block',
  'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)',
  'box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset, 0 5px 3px -5px rgba(0, 0, 0, 0.5), 0 -13px 5px -10px rgba(255, 255, 255, 0.4) inset',
  'line-height: 20px',
  'text-align: center',
  'font-size: 16px',
  'font-weight: bold',
  'padding-left: 120px',
  'padding-top: 10px',
  'padding-right: 120px',
  'padding-bottom: 10px',
  'border-radius: 0.5em',
].join('; ');

/** 카드 도메인 */
export const HPP_BASE_URL = {
  // 로컬, 개발계 - 내부 / 내부SSL / 외부
  TEST: {
    INNER: 'http://developwww.samsungcard.com',
    SSL: 'https://developwww.samsungcard.com',
    EXTERNAL: 'https://developwww.samsungcard.com',
  },

  // 검증계
  STAGE: 'http://www.samsungcard.com',

  // 운영계
  PROD: 'https://www.samsungcard.com',
};

/** 생명 도메인 */
export const SLF_BASE_URL = {
  // 로컬, 개발계
  TEST: {
    HMPG: 'https://vmonimo.samsunglife.com',
    DIRCT: 'https://sdirect.samsunglife.com',
  },

  // 검증계
  STAGE: {
    HMPG: 'https://vmonimo.samsunglife.com',
    DIRCT: 'https://sdirect.samsunglife.com',
  },

  // 운영계
  PROD: {
    HMPG: 'https://monimo.samsunglife.com',
    DIRCT: 'https://direct.samsunglife.com',
  },
};

/** 화재 도메인 */
export const SSF_BASE_URL = {
  // 로컬, 개발계
  TEST: {
    HMPG: 'https://homepage-vstg.samsungfire.com:13025',
    DIRCT: 'https://direct-vstg.samsungfire.com:13005',
  },

  // 검증계
  STAGE: {
    HMPG: 'https://homepage-vstg.samsungfire.com:13025',
    DIRCT: 'https://direct-vstg.samsungfire.com:13005',
  },

  // 운영계
  PROD: {
    HMPG: 'https://www.samsungfire.com',
    DIRCT: 'https://direct.samsungfire.com',
  },
};

/** 증권 도메인 */
export const SSS_BASE_URL = {
  // 로컬, 개발계
  TEST: 'https://tmonimo.samsungpop.com',

  // 검증계
  STAGE: 'https://tmonimo.samsungpop.com',

  // 운영계
  PROD: 'https://monimo.samsungpop.com',
};

import _ from 'lodash';

// API
import {
  privateSignResult,
  collect,
  getCollectStatus,
  getCollectStatusLoading,
  collectByOrg,
  collectByOrgList,
} from '@/api/mdp/jo';
import { putUAuthInfo, getMrktAgYn, getPsnzBnnr, getUserExistCheck } from '@/api/mdp/cmn';
import { burConnCut, connBurDtlInfInqr } from '@/api/mdp/as';
import { selCurDate, inqrSrnActvPly } from '@/api/pfm/cmn';
import { astsBasInf, insrInf } from '@/api/mdp/is';
import { getFunctionOnOff, getMemberInfo } from '@/api/mdp/ma';

// Utils
import { addComma } from '@/utils';
import commonUtil, { getCacheData, setCacheData, setLocalStorageData, getLocalStorageData } from '@/utils/common-util';
import {
  getMdpIspUrl,
  toHppUrl,
  toPfmUrl,
  toSlfHmpgUrl,
  toSsfHmpgUrl,
  toSssUrl,
  toSlfDirctUrl,
  toSsfDirctUrl,
} from '@/utils/url-parser';
import stringUtil from '@/utils/string-util';
import DateUtil from '@/utils/date-util';
import appUtill from '@/src/static/js/monimo/app/bridge/modules/util';
import TypeUtil from '@/utils/type-util';

/********************************
 * Mydata 관련 유틸
 ********************************/

export default {
  MYDATA_MAIN_REFRESH: 'MYDATA_MAIN_REFRESH', // 마이데이터 자산홈 새로고침 캐시
  MD_BIG_BANNER_HIDE: 'MD_BIG_BANNER_HIDE', // 빅배너 숨김 캐시
  MD_BANNER_HIDE: 'MD_BANNER_HIDE', // 배너 숨김 캐시
  MD_SECESSION: 'MD_SECESSION', // 회원 탈회 시 캐시
  MD_ASSET_DELAY_UPDATE: 'MD_ASSET_DELAY_UPDATE', // 자산 연장 시 업데이트 캐시
  DELETE_ASSET_YN: 'DELETE_ASSET_YN', // 자산 삭제 여부 캐시
  DELETE_ASSET_BACK_PAGE: 'DELETE_ASSET_BACK_PAGE', // 자산 삭제 완료 후 리턴 페이지 캐시
  MD_CSM_CLDN_YN: 'MD_CSM_CLDN_YN', // 마이데이터 소비 캘린더 이동 여부 캐시
  MD_CERTA_DV_C: 'MD_CERTA_DV_C', // 마이데이터 인증수단값 캐시
  MD_NONCE_LIST_BY_ORG_CODE: 'MD_NONCE_LIST_BY_ORG_CODE', // 기관별 Nonce List 캐시
  AUTH_CERT_TX_ID: 'AUTH_CERT_TX_ID', // 사설인증 CERT_TX_ID값 캐시
  AUTH_SIGN_TX_ID: 'AUTH_SIGN_TX_ID', // 사설인증 SIGN_TX_ID값 캐시
  MD_MAIN_VIEW_TYPE: 'MD_MAIN_VIEW_TYPE', // 마이데이터 자산홈 화면유형값 캐시
  MD_MAIN_QUICK_SEND_MONEY: 'MD_MAIN_QUICK_SEND_MONEY', // 마이데이터 메인 퀵송금로직 캐시
  PRIVATE_CONSENT_ARR: 'PRIVATE_CONSENT_ARR', // 사설인증 전송요구내역목록 캐시
  PRIVATE_SIGN_PARAMS: 'PRIVATE_SIGN_PARAMS', // 사설인증파라미터 캐시
  AC_NCKNME_CHG: 'AC_NCKNME_CHG', // 계좌별명변경 캐시
  MD_CSM_AC_SUM_DATA: 'MD_CSM_AC_SUM_DATA', // 마이데이터 소비 계좌금액필터 데이터 캐시
  MD_JOIN_SEARCH_BACK_DATA: 'MD_JOIN_SEARCH_BACK_DATA', // 마이데이터 가입및동의 검색화면 뒤로가기 데이터 캐시
  MD_COMMON_COLLECT_REQ: 'MD_COMMON_COLLECT_REQ', // 마이데이터공통통합수집요청 캐시
  MD_FUNC_ON_OFF_LIST: 'MD_FUNC_ON_OFF_LIST', // 마이데이터 기능On/Off 데이터 목록 캐시
  MD_JOIN_SEARCHED_DATA: 'MD_JOIN_SEARCHED_DATA', // 마이데이터 가입및동의 검색 데이터 캐시
  MD_UPDATE: 'MD_UPDATE', // 마이데이터 자산홈 Refresh 여부 캐시
  MD_P_DTL_PAGE: 'MD_P_DTL_PAGE', // 마이데이터 포인트머니 디테일 페이지 캐시
  MD_CSM_P_SUM_DATA: 'MD_CSM_P_SUM_DATA', // 마이데이터 소비 포인트 합계 데이터 캐시
  MD_CSM_CARD_SUM_DATA: 'MD_CSM_CARD_SUM_DATA', // 마이데이터 소비 카드 합계 데이터 캐시
  AUTH_INFO_DATA: 'AUTH_INFO_DATA', // 인증정보 캐시
  UPFMMA0701M2_DATA: 'UPFMMA0701M2_DATA', // 마이데이터 자산관리 특정화면 캐시
  MD_ASSET_UPDATE: 'MD_ASSET_UPDATE', // 마이데이터 자산 업데이트 캐시
  MD_CSM_P_CTG: 'MD_CSM_P_CTG', // 마이데이터 소비 포인트 카테고리 캐시
  MD_CSM_AC_CTG: 'MD_CSM_AC_CTG', // 마이데이터 소비 계좌 카테고리 캐시
  MD_AC_DTL_PAGE: 'MD_AC_DTL_PAGE', // 마이데이터 계좌 상세 페이지 캐시
  MD_ORG_DELETE_YN: 'MD_ORG_DELETE_YN', // 마이데이터 기관 삭제 여부 캐시
  MD_ORG_DELETE_FLAG: 'MD_ORG_DELETE_FLAG', // [F2_SR_119] 개별자산삭제메시지처리 : 마이데이터 기관 삭제 플래그 캐시 (유지할게요, 삭제할게요)
  MD_ORG_DELETE_NAME: 'MD_ORG_DELETE_NAME', // 마이데이터 삭제 기관명 캐시
  MD_CARD_NM_CH_YN: 'MD_CARD_NM_CH_YN', // 마이데이터 카드명 변경 여부 캐시
  MD_IS_NM_CH_YN: 'MD_IS_NM_CH_YN', // 마이데이터 보험명 변경 여부 캐시
  MD_COLLECT_FAIL_DATA: 'MD_COLLECT_FAIL_DATA', // 마이데이터 수집 실패 데이터 캐시
  MD_JOIN_ORG_DATA: 'MD_JOIN_ORG_DATA', // 마이데이터 가입및동의 기관 데이터 캐시
  MD_CARD_DTL_PAGE: 'MD_CARD_DTL_PAGE', // 마이데이터 카드 상세 페이지 캐시
  MD_CSM_CARD_CTG: 'MD_CSM_CARD_CTG', // 마이데이터 소비 카드 카테고리 캐시
  U_AUTH_KEY: 'U_AUTH_KEY', // 마이데이터 사설인증키 캐시
  MD_JOIN_AGREE_DATA: 'MD_JOIN_AGREE_DATA', // 마이데이터 가입및동의 첫 데이터 캐시
  MD_AUTH_FIRST_YN: 'MD_AUTH_FIRST_YN', // 마이데이터 1차인증 여부 캐시
  MD_JOIN_SECOND_AUTH_DATA: 'MD_JOIN_SECOND_AUTH_DATA', // 마이데이터 가입및동의 2차인증 데이터 캐시
  MD_JOIN_AUTH_PREV_DATA: 'MD_JOIN_AUTH_PREV_DATA', // 마이데이터 가입및동의 인증 전 데이터 캐시
  MD_COLLECT_ORG_LIST: 'MD_COLLECT_ORG_LIST', // 마이데이터 수집 기관 목록 캐시
  MD_COLLECT_: 'MD_COLLECT_', // 마이데이터 수집
  MD_COLLECT_CARD_LIST: 'MD_COLLECT_CARD_LIST', // 마이데이터 카드 목록 수집
  MD_CMS_ORG_CODE_LIST: 'MD_CMS_ORG_CODE_LIST', // 마이데이터 소비 기관코드 목록 캐시
  MD_OPBK_UPDATE: 'MD_OPBK_UPDATE', // 오픈뱅킹 송금 업데이트 시간
  MD_GHC_HEALTH_PRG_STC: '', // 마이데이터 GHC 건강점수 산출 진행상태코드 변수
  MD_GHC_HEALTH_PRG_STC_LIST: [], // 마이데이터 GHC 건강점수 산출 진행상태코드 목록
  MD_PSNZ_BNNR_TAGGING_DATA: [], // 개인화 배너 태깅 목록
  MD_PSNZ_BNNR_TAGGING_STOP: false, // 개인화 배너 태깅 STOP
  MD_SRCH_PAGE_DATA: 'MD_SRCH_PAGE_DATA', // 마이데이터 검색 페이지 데이터 캐시
  MD_SRCH_BACK_YN: 'MD_SRCH_BACK_YN', // 마이데이터 검색 백버튼 여부
  MD_AC_DTL_ACCESS_YN: 'MD_AC_DTL_ACCESS_YN', // 계좌 상세내역 접속 성공여부
  MD_HEALTH_CSM_SUM_YN: 'MD_HEALTH_CSM_SUM_YN', // 마이데이터 건강점수 소비반영 여부
  serverErrorMsg: {
    id: 'serverErrorMsg',
    wrapStyleClass: 'message-dialog',
    iconName: 'icon-error-caution',
    contentText: '처리 도중 오류가 발생하였습니다.',
    buttons: [
      {
        btnId: 'btnOk',
        btnStyleClass: 'primary',
        btnText: '확인',
        eventName: 'closeServerErrorMsg',
      },
    ],
    closeServerErrorMsg: btnId => {
      window?.$nuxt?.context?.$bus?.$emit('commonDialogState', false);
      // selfClose(string): 함수 호출 이후 이전 webview를 닫을 수 있음
      window?.$nuxt?.$ongobridge?.openNative({ target: 'today', selfClose: 'Y' });
    },
  },
  // 계좌, 소비, 투자, 보험, 대출, 연금, 포인트머니, 건강, 신용점수, 환전지갑, 자동차, 부동산, 기타자산
  myDataComponentTrgt: ['01', '02', '03', '04', '05', '06', '07', '08', '51', '52', '53', '54', '55'],
  /**
   * 1차인증 시 자산별 데이터
   * @param signedData
   * @param that
   * @returns {{requestType: string, signedPersonInfoReqLen: number, consentLen: number, consentNonce: (string|*), consent: string, caCode, relayOrgCode, signedPersonInfoReq: (string|*), ucpidNonce: (string|*), passwordLen, password: *, orgCode, consentType: string, authType: string, serviceId: string}|boolean|{passwordLen, password: *, requestType: string, consentLen: number, orgCode, consentType: string, authType: string, consent: string, serviceId: string, caCode, relayOrgCode, certTxId: string}}
   */
  async getFirstUAuthTokenSubQVO(signedData, that) {
    const consentTarget = that.consentArr.filter(item => item.snd_org_code === signedData.orgCode);
    const orgData = that.orgData.filter(item => item.orgCode === signedData.orgCode);

    let authType; // auth_type
    if (that.authVal === '03' || that.authVal === '04') {
      // 금융인증서 / 공동인증서
      authType = '0';
    } else {
      // 사설인증
      authType = '1';
    }

    if (consentTarget && orgData) {
      const consentStr = JSON.stringify(consentTarget[0]);
      if (that.authVal === '01' || that.authVal === '02' || that.authVal === '05') {
        // 사설인증서 - 네이버/카카오/테스트베드
        const authCertTxId = await getCacheData(this.AUTH_CERT_TX_ID);
        let caCode = '';
        if (that.authVal === '01') {
          // 네이버 인증서의 경우
          caCode = 'ZYAAQH0000';
        } else if (that.authVal === '02') {
          // 카카오톡 인증서의 경우
          caCode = 'ZYAAPC0000';
        }
        return {
          orgCode: signedData.orgCode,
          relayOrgCode: orgData[0].relayOrgCode,
          caCode,
          requestType: '0', // 자산목록조회 전송요구(1차) : 0 (전송요구내역의 scope에 xxx.list만 존재하는 경우) /// 고객선택자산에 대한 세부 전송요구(2차) : 1
          passwordLen: signedData.signedConsent.length,
          password: signedData.signedConsent,
          authType, // 본인확인 이용 여부
          consentType: '1', // 전자서명 유형
          consentLen: consentStr.length, // consent 항목 길이
          consent: consentStr, // 전송요구내역 : Auth AP에서 전송요구 내역 저장을 위해 필수로 함
          // signedPersonInfoReqLen: signedData.signedPersonInfoReq.length, // 본인확인 이용동의 전자서명 항목 길이 (signedPersonInfoReq의 길이)
          // signedPersonInfoReq: signedData.signedPersonInfoReq, // 본인확인 이용동의 전자서명 (signedPersonInfoReq)
          // consentNonce: that.consentNonce, // 재전송공격 방지정보 1 (consentNonce)
          // ucpidNonce: that.consentNonce, // 재전송공격 방지정보 2 (ucpidNonce)
          certTxId: authCertTxId, // 인증사업자 트랜잭션 아이디
          serviceId: '', // 서비스번호
          txId: signedData.txId,
        };
      } else if (that.authVal === '03' || that.authVal === '04') {
        // 공동인증서/금융인증서
        const nonceInfoTarget = that.nonceListByOrgCode.filter(item => item.orgCode === signedData.orgCode)[0];
        let caCode = '';
        if (that.authVal === '03') {
          // 금융인증서의 경우
          caCode = 'ZXAAEA0000';
        } else if (that.authVal === '04') {
          // 공동인증서의 경우
          if (that.caOrg === 'KICA') {
            // 한국정보인증
            caCode = 'ZXAACA0000';
          } else if (that.caOrg === 'SignKorea') {
            // 코스콤
            caCode = 'ZXAACP0000';
          } else if (that.caOrg === 'yessign') {
            // 금결원
            caCode = 'ZXAAEA0000';
          } else if (that.caOrg === 'CrossCert') {
            // 한국전자인증
            caCode = 'ZXAAQB0000';
          }
        }
        return {
          orgCode: signedData.orgCode,
          relayOrgCode: orgData[0].relayOrgCode,
          caCode,
          caOrg: that.caOrg,
          requestType: '0',
          passwordLen: signedData.signedConsent.length,
          password: signedData.signedConsent,
          authType, // 본인확인 이용 여부
          consentType: '0', // 전자서명 유형
          consentLen: consentStr.length, // consent 항목 길이
          consent: consentStr, // 전송요구내역 : Auth AP에서 전송요구 내역 저장을 위해 필수로 함
          signedPersonInfoReqLen: signedData.signedPersonInfoReq.length, // 본인확인 이용동의 전자서명 항목 길이 (signedPersonInfoReq의 길이)
          signedPersonInfoReq: signedData.signedPersonInfoReq, // 본인확인 이용동의 전자서명 (signedPersonInfoReq)
          consentNonce: nonceInfoTarget.consentNonce, // 재전송공격 방지정보 1 (consentNonce)
          ucpidNonce: nonceInfoTarget.ucpidNonce, // 재전송공격 방지정보 2 (ucpidNonce)
          // certTxId: '', // 인증사업자 트랜잭션 아이디
          serviceId: '', // 서비스번호
        };
      }
    }
    return false;
  },
  /**
   * 2차인증 시 자산별 데이터
   * @param signedData
   * @param that
   * @returns {{passwordLen: number, password: (string|*), requestType: string, consentLen, orgCode, consentType: string, authType: string, consent, serviceId: string, caCode, relayOrgCode, certTxId: string}|boolean|{requestType: string, signedPersonInfoReqLen: number, consentLen, consentNonce: string, consent, caCode, relayOrgCode, signedPersonInfoReq: (string|*), ucpidNonce: string, passwordLen: number, password: (string|*), orgCode, consentType: string, authType: string, serviceId: string}}
   */
  async getSecondUAuthTokenSubQVO(signedData, that) {
    const consentTarget = that.consentArr.filter(item => item.snd_org_code === signedData.orgCode);
    const orgData = that.orgData.filter(item => item.orgCode === signedData.orgCode);
    let authType; // auth_type
    let consentType = '';
    if (consentTarget && orgData) {
      let consentStr = signedData.signedConsent;
      let relayOrgCode;
      if (signedData.orgCode === 'D1AAEV0000' || signedData.orgCode === 'D3AAEV0000') {
        authType = '0';
        relayOrgCode = '';
        signedData.signedConsent = '';
        consentType = '1';
      } else {
        if (that.authVal === '03' || that.authVal === '04') {
          // 금융인증서 / 공동인증서
          authType = '0';
          consentType = '0';
        } else {
          // 사설인증
          authType = '1';
          consentType = '1';
        }
        consentStr = JSON.stringify(consentTarget[0]);
        relayOrgCode = orgData[0].relayOrgCode;
      }
      if (that.authVal === '01' || that.authVal === '02' || that.authVal === '05') {
        // 사설인증서 - 네이버/카카오/테스트베드
        const authCertTxId = await getCacheData(this.AUTH_CERT_TX_ID);
        let caCode = '';
        if (that.authVal === '01') {
          // 네이버 인증서의 경우
          caCode = 'ZYAAQH0000';
        } else if (that.authVal === '02') {
          // 카카오톡 인증서의 경우
          caCode = 'ZYAAPC0000';
        }
        return {
          orgCode: signedData.orgCode,
          relayOrgCode,
          caCode,
          requestType: '1', // 자산목록조회 전송요구(1차) : 0 (전송요구내역의 scope에 xxx.list만 존재하는 경우) /// 고객선택자산에 대한 세부 전송요구(2차) : 1
          passwordLen: signedData.signedConsent.length,
          password: signedData.signedConsent,
          authType, // 본인확인 이용 여부
          consentType, // 전자서명 유형
          consentLen: consentStr.length, // consent 항목 길이
          consent: consentStr, // 전송요구내역 : Auth AP에서 전송요구 내역 저장을 위해 필수로 함
          // signedPersonInfoReqLen: signedData.signedPersonInfoReq.length, // 본인확인 이용동의 전자서명 항목 길이 (signedPersonInfoReq의 길이)
          // signedPersonInfoReq: signedData.signedPersonInfoReq, // 본인확인 이용동의 전자서명 (signedPersonInfoReq)
          // consentNonce: that.consentNonce, // 재전송공격 방지정보 1 (consentNonce)
          // ucpidNonce: that.consentNonce, // 재전송공격 방지정보 2 (ucpidNonce)
          certTxId: authCertTxId, // 인증사업자 트랜잭션 아이디
          serviceId: '', // 서비스번호
          txId: signedData.txId,
        };
      } else if (that.authVal === '03' || that.authVal === '04') {
        // 공동인증서/금융인증서
        let nonceInfoTarget = {};
        if (signedData.orgCode === 'D1AAEV0000' || signedData.orgCode === 'D3AAEV0000') {
          nonceInfoTarget = {
            consentNonce: '',
            ucpidNonce: '',
          };
        } else {
          nonceInfoTarget = that.nonceListByOrgCode.filter(item => item.orgCode === signedData.orgCode)[0];
        }
        let caCode = '';
        if (that.authVal === '03') {
          // 금융인증서의 경우
          caCode = 'ZXAAEA0000';
        } else if (that.authVal === '04') {
          // 공동인증서의 경우
          if (that.caOrg === 'KICA') {
            // 한국정보인증
            caCode = 'ZXAACA0000';
          } else if (that.caOrg === 'SignKorea') {
            // 코스콤
            caCode = 'ZXAACP0000';
          } else if (that.caOrg === 'yessign') {
            // 금결원
            caCode = 'ZXAAEA0000';
          } else if (that.caOrg === 'CrossCert') {
            // 한국전자인증
            caCode = 'ZXAAQB0000';
          }
        }
        return {
          orgCode: signedData.orgCode,
          relayOrgCode,
          caCode,
          requestType: '1',
          caOrg: that.caOrg,
          passwordLen: signedData.signedConsent.length,
          password: signedData.signedConsent,
          authType, // 본인확인 이용 여부
          consentType, // 전자서명 유형
          consentLen: consentStr.length, // consent 항목 길이
          consent: consentStr, // 전송요구내역 : Auth AP에서 전송요구 내역 저장을 위해 필수로 함
          signedPersonInfoReqLen: signedData.signedPersonInfoReq.length, // 본인확인 이용동의 전자서명 항목 길이 (signedPersonInfoReq의 길이)
          signedPersonInfoReq: signedData.signedPersonInfoReq, // 본인확인 이용동의 전자서명 (signedPersonInfoReq)
          consentNonce: nonceInfoTarget.consentNonce, // 재전송공격 방지정보 1 (consentNonce)
          ucpidNonce: nonceInfoTarget.ucpidNonce, // 재전송공격 방지정보 2 (ucpidNonce)
          // certTxId: '', // 인증사업자 트랜잭션 아이디
          serviceId: '', // 서비스번호
        };
      }
    }
    return false;
  },
  getCommonCertParams(url, signReqInfo) {
    return {
      userAgreement:
        '금융분야 마이데이터 통합인증을 위한 인증서 본인확인서비스 이용약관, 개인정보 처리, 고유식별정보수집·이용 및 위탁에 동의합니다.',
      realName: 'Y',
      gender: 'Y',
      national: 'Y',
      birth: 'Y',
      ci: 'Y',
      url,
      signReqInfo,
    };
  },
  /**
   * base64 인코딩된 데이터를 url safe하게 변경해서 return
   * @param base64Data
   * @returns Base 64 Url safe data
   */
  getUrlSafeData(base64Data) {
    return base64Data.replace(/\+/gi, '-').replace(/\//gi, '_');
  },
  getCommonCertSignData(signedDataList) {
    for (let i = 0; i < signedDataList.length; i++) {
      // signedPersonInfoReq
      signedDataList[i].signedPersonInfoReq = this.getUrlSafeData(signedDataList[i].ucpidSignData);
      // password
      signedDataList[i].signedConsent = this.getUrlSafeData(signedDataList[i].consentSignData);
      signedDataList[i].ucpidSignData = '';
      signedDataList[i].consentSignData = '';
    }
    return signedDataList;
  },
  getRepayOrgImage(item) {
    let code = '';
    let imgUrl;
    if (!item.imgchange) {
      // 1차적으로 해당 기관코드가 있는 이미지인인지 Check
      code = '';
      imgUrl =
        window?.$nuxt?.context?.env?.WCMS_BASE_URL +
        '/monimo/mydata/logo/smoni_611/' +
        code +
        item.repayOrgCode +
        '.png';
    } else if (!item.imgchange2) {
      // 국내은행 Check
      code = 'A1';
      imgUrl =
        window?.$nuxt?.context?.env?.WCMS_BASE_URL +
        '/monimo/mydata/logo/smoni_611/' +
        code +
        item.repayOrgCode +
        '.png';
    } else if (!item.imgchange3) {
      // 외국은행 Check
      code = 'A2';
      imgUrl =
        window?.$nuxt?.context?.env?.WCMS_BASE_URL +
        '/monimo/mydata/logo/smoni_611/' +
        code +
        item.repayOrgCode +
        '.png';
    } else if (!item.imgchange4) {
      // 저축은행 Check
      code = 'A3';
      imgUrl =
        window?.$nuxt?.context?.env?.WCMS_BASE_URL +
        '/monimo/mydata/logo/smoni_611/' +
        code +
        item.repayOrgCode +
        '.png';
    } else if (!item.imgchange5) {
      // 상호금융 Check
      code = 'A4';
      imgUrl =
        window?.$nuxt?.context?.env?.WCMS_BASE_URL +
        '/monimo/mydata/logo/smoni_611/' +
        code +
        item.repayOrgCode +
        '.png';
    } else if (!item.imgchange6) {
      // 전자금융 Check
      code = 'C1';
      imgUrl =
        window?.$nuxt?.context?.env?.WCMS_BASE_URL +
        '/monimo/mydata/logo/smoni_611/' +
        code +
        item.repayOrgCode +
        '.png';
    } else {
      imgUrl = window?.$nuxt?.context?.env?.WCMS_BASE_URL + '/monimo/mydata/logo/smoni_611/Bank_Default.png';
    }
    window.$nuxt.$log.log(imgUrl);
    return imgUrl;
  },
  setRepayOrgDefaultImg(item) {
    window.$nuxt.$log.log('error default img');
    if (!item.imgchange) {
      window?.$nuxt?.$set(item, 'imgchange', true);
    } else if (!item.imgchange2) {
      window?.$nuxt?.$set(item, 'imgchange2', true);
    } else if (!item.imgchange3) {
      window?.$nuxt?.$set(item, 'imgchange3', true);
    } else if (!item.imgchange4) {
      window?.$nuxt?.$set(item, 'imgchange4', true);
    } else if (!item.imgchange5) {
      window?.$nuxt?.$set(item, 'imgchange5', true);
    } else if (!item.imgchange6) {
      window?.$nuxt?.$set(item, 'imgchange6', true);
    }
  },
  getFirstTokenConsentData(item, nonceListByOrgCode, that, orgScopeList) {
    const nonceInfoTarget = nonceListByOrgCode.filter(data => data.orgCode === item.orgCode)[0];
    that.$log.log('getFirstTokenConsentData called');
    const endDate = that.$dateUtil.formatDate(that.$dateUtil.add(that.$dateUtil.now('YYYYMMDD'), 7), 'YYYYMMDD');
    let orgScopeInfo = orgScopeList.filter(data => data.orgCode === item.orgCode)[0];
    orgScopeInfo = orgScopeInfo.scopes;
    orgScopeInfo = orgScopeInfo.filter(data => data.includes('.list'));
    const targetInfo = [];
    for (let j = 0; j < orgScopeInfo.length; j++) {
      targetInfo.push({
        scope: orgScopeInfo[j],
      });
    }
    return {
      orgCode: item.orgCode, // 마이데이터 정보제공자 기관코드(org_code) :: 신용정보원에서 할당한 10자리 기관코드
      ucpidRequestInfo: {
        userAgreement:
          '금융분야 마이데이터 통합인증을 위한 인증서 본인확인서비스 이용약관, 개인정보 처리, 고유식별정보수집·이용 및 위탁에 동의합니다.', // 본인확인 이용 동의내역 문자열(UTF-8)
        // (고정값) "금융분야 마이데이터 통합인증을
        // 위한 인증서 본인확인서비스 이용약관,
        // 개인정보 처리, 고유식별정보수집·이용 및
        // 위탁에 동의합니다."
        userAgreeInfo: {
          // 요청하고자 하는 본인확인정보
          realName: true, // 실명
          gender: true, // 성별
          nationalInfo: true, // 국적
          birthDate: true, // 생년월일
          ci: true, // CI 정보
        },
        ispUrlInfo: getMdpIspUrl(), // 마이데이터 서비스 도메인정보
        ucpidNonce: nonceInfoTarget.ucpidNonce, // 마이데이터 서버가 생성한 Nonce
        // Base64 url-safe로 인코딩한 값
        // 128 bits 숫자 (인코딩 이전 원문 기준)
        // 길이 및 생성 방법 : '마이데이터 통합인증
        // 기술규격' 참고
      },
      consentInfo: {
        consent: {
          snd_org_code: item.orgCode,
          rcv_org_code: 'ZVAAEV0000',
          is_scheduled: 'false',
          // fnd_cycle: '1/w',
          // add_cycle: '1/w',
          end_date: endDate,
          purpose: '자산목록조회 전송요구',
          period: endDate,
          target_info: targetInfo,
        }, // 전송요구내역
        // 상세 JSON 항목은 '마이데이터 통합인증 기술규격'의
        // '전송요구내역 규격 (JSON)' 항목을 참고
        // https://www.mydatacenter.or.kr:3441/myd/bbsctt/normal1/normal/bbsctt.do
        consentNonce: nonceInfoTarget.consentNonce, // 마이데이터 서버가 생성한 Nonce
        // Base64 url-safe로 인코딩한 값
        // 128 bits 숫자 (인코딩 이전 원문 기준)
        // 길이 및 생성 방법 : '마이데이터 통합인증
        // 기술규격' 참고
      },
    };
  },
  getSecondTokenContentData(item, nonceListByOrgCode, that, isAllAsset, isOpenBanking) {
    let result;
    const selectedLen = that.selectedData.filter(data => data.orgCode === item.orgCode).length;
    const isSelected = selectedLen >= 0;
    const isAllAssetSelected =
      selectedLen >= 200 ? that.allAssetData.filter(data => data === item.orgCode).length > 0 : true;
    if ((isSelected && selectedLen < 200) || (selectedLen >= 200 && isAllAssetSelected)) {
      const nonceInfoTarget = nonceListByOrgCode.filter(data => data.orgCode === item.orgCode)[0];
      that.$log.log('getSecondTokenContentData called');
      const scopeData = [];
      if (!isOpenBanking) {
        // 오픈뱅킹이 아닌 경우
        const targetSelectedData = that.selectedData.filter(data => data.orgCode === item.orgCode);
        // eslint-disable-next-line no-undef
        const baseScopeData = _.mapValues(_.groupBy(targetSelectedData, 'baseScope'));
        const baseScopeKey = Object.keys(baseScopeData);
        for (let i = 0; i < baseScopeKey?.length; i++) {
          const scopeSubData = {
            scope: baseScopeKey[i],
          };
          for (let j = 0; j < baseScopeData[baseScopeKey[i]]?.length; j++) {
            scopeSubData.asset_list = that.getAssetData(baseScopeData[baseScopeKey[i]], isAllAsset);
          }
          scopeData.push(scopeSubData);
        }
        // eslint-disable-next-line no-undef
        const extraScopeData = _.groupBy(
          targetSelectedData.filter(v => v.extraScope),
          'extraScope',
        );
        const extraScopeKey = Object.keys(extraScopeData);
        for (let i = 0; i < extraScopeKey?.length; i++) {
          const existScope = scopeData.find(item => item.scope === extraScopeKey[i]);
          const scopeSubData = {
            scope: extraScopeKey[i],
          };
          scopeSubData.asset_list = that.getAssetData(extraScopeData[extraScopeKey[i]], isAllAsset);
          // 기존에 있는 scope 라면 array concat 하고 없는 scope 라면 새로 대입함
          if (existScope) {
            existScope.asset_list = existScope.asset_list.concat(scopeSubData.asset_list);
          } else {
            scopeData.push(scopeSubData);
          }
        }
        // 자산연결시 .list scope 추가
        const commonScopeData = scopeData[0].scope;
        const commonScope = commonScopeData.split('.')[0];
        scopeData.push({
          scope: commonScope + '.list',
        });

        if (that.cardProps[item.orgCode]?.point) {
          const pointData = {
            scope: 'card.point',
          };
          scopeData.push(pointData);
        }
        if (that.cardProps[item.orgCode]?.bill) {
          const billData = {
            scope: 'card.bill',
          };
          scopeData.push(billData);
        }
        if (that.cardProps[item.orgCode]?.loan) {
          const loanData = {
            scope: 'card.loan',
          };
          scopeData.push(loanData);
        }
      } else {
        // 오픈뱅킹인 경우
        let mdAsstSeqNo;
        if (nonceInfoTarget.orgCode === 'A1AAEO0000') {
          // 주식회사 국민은행
          mdAsstSeqNo = '0000000';
        } else if (
          nonceInfoTarget.orgCode === 'A1AAEQ0000' ||
          nonceInfoTarget.orgCode === 'A1AAAD0000' ||
          nonceInfoTarget.orgCode === 'A1AAJB0000' ||
          nonceInfoTarget.orgCode === 'A1AAIP0000' ||
          nonceInfoTarget.orgCode === 'A1AAHP0000'
        ) {
          // 중소기업은행 / 한국산업은행 / 케이뱅크은행 / 주식회사 제주은행 / (주)전북은행
          mdAsstSeqNo = '0';
        }
        const opbkAcKndDvCArr = item.opbkAcKndDvCArr; // 오픈뱅킹 계좌등록구분코드 배열
        const isExistDepositInvestAccount =
          opbkAcKndDvCArr.filter(item => item.opbkAcKndDvC === '1' || item.opbkAcKndDvC === '2').length > 0;
        if (isExistDepositInvestAccount) {
          // 1(수시 입출금) or 2(예적금)의 경우
          const assetList = opbkAcKndDvCArr.filter(item => item.opbkAcKndDvC === '1' || item.opbkAcKndDvC === '2');
          const resAssetList = [];
          for (const asset of assetList) {
            const assetData = { asset: asset.mdAsstVl, seqno: mdAsstSeqNo };
            resAssetList.push(assetData);
          }
          scopeData.push({
            scope: 'bank.deposit', // 자유입출금, 예금
            asset_list: resAssetList,
          });
        }
        const isExistInvestAccount = opbkAcKndDvCArr.filter(item => item.opbkAcKndDvC === '6').length > 0;
        if (isExistInvestAccount) {
          // 6(수익증권)의 경우
          const assetList = opbkAcKndDvCArr.filter(item => item.opbkAcKndDvC === '6');
          const resAssetList = [];
          for (const asset of assetList) {
            const assetData = { asset: asset.mdAsstVl, seqno: mdAsstSeqNo };
            resAssetList.push(assetData);
          }
          scopeData.push({
            scope: 'bank.invest', // 수익증권
            asset_list: resAssetList,
          });
        }
        scopeData.push({
          scope: 'bank.list', // 필수
        });
      }

      const goingTarget = that.goingListFilters[that.selectedGoingItem];
      let endDate = that.$dateUtil.formatDate(
        that.$dateUtil.add(that.$dateUtil.now('YYYYMMDD'), goingTarget.value, 'M'),
        'YYYYMMDD',
      );
      endDate = that.$dateUtil.formatDate(that.$dateUtil.subtract(endDate, 1, 'd'), 'YYYYMMDD');

      let consent = {
        snd_org_code: item.orgCode,
        rcv_org_code: 'ZVAAEV0000',
        end_date: endDate,
        purpose: '자산연결 전송요구',
        period: '99991231',
        target_info: scopeData,
      };
      consent = that.$mydataUtil.getSnsvConsent(
        consent,
        that.orgData,
        item.orgCode,
        that.snsvAgree,
        that,
        isOpenBanking,
      );

      result = {
        orgCode: item.orgCode, // 마이데이터 정보제공자 기관코드(org_code) :: 신용정보원에서 할당한 10자리 기관코드
        ucpidRequestInfo: {
          userAgreement:
            '금융분야 마이데이터 통합인증을 위한 인증서 본인확인서비스 이용약관, 개인정보 처리, 고유식별정보수집·이용 및 위탁에 동의합니다.', // 본인확인 이용 동의내역 문자열(UTF-8)
          // (고정값) "금융분야 마이데이터 통합인증을
          // 위한 인증서 본인확인서비스 이용약관,
          // 개인정보 처리, 고유식별정보수집·이용 및
          // 위탁에 동의합니다."
          userAgreeInfo: {
            // 요청하고자 하는 본인확인정보
            realName: true, // 실명
            gender: true, // 성별
            nationalInfo: true, // 국적
            birthDate: true, // 생년월일
            ci: true, // CI 정보
          },
          ispUrlInfo: getMdpIspUrl(), // 마이데이터 서비스 도메인정보
          ucpidNonce: nonceInfoTarget.ucpidNonce, // 마이데이터 서버가 생성한 Nonce
          // Base64 url-safe로 인코딩한 값
          // 128 bits 숫자 (인코딩 이전 원문 기준)
          // 길이 및 생성 방법 : '마이데이터 통합인증
          // 기술규격' 참고
        },
        consentInfo: {
          consent, // 전송요구내역
          // 상세 JSON 항목은 '마이데이터 통합인증 기술규격'의
          // '전송요구내역 규격 (JSON)' 항목을 참고
          // https://www.mydatacenter.or.kr:3441/myd/bbsctt/normal1/normal/bbsctt.do
          consentNonce: nonceInfoTarget.consentNonce, // 마이데이터 서버가 생성한 Nonce
          // Base64 url-safe로 인코딩한 값
          // 128 bits 숫자 (인코딩 이전 원문 기준)
          // 길이 및 생성 방법 : '마이데이터 통합인증
          // 기술규격' 참고
        },
      };

      const activeAutoUpdateId = that.activeAutoUpdateData;
      if (activeAutoUpdateId === 0) {
        // 주 1회
        result.consentInfo.consent.is_scheduled = 'true';
        result.consentInfo.consent.fnd_cycle = '1/w';
        result.consentInfo.consent.add_cycle = '1/w';
      } else if (activeAutoUpdateId === 1) {
        // 하지않음
        result.consentInfo.consent.is_scheduled = 'false';
      }
    }
    return result;
  },
  getSnsvConsent(consent, orgData, orgCode, snsvAgree, that, isOpenBanking) {
    let target;
    if (!isOpenBanking) {
      // 일반적인 연결
      target = orgData;

      const isExistBank = target.filter(item => item.industry === 'bank' && item.orgCode === orgCode).length > 0; // 은행
      const isExistCard = target.filter(item => item.industry === 'card' && item.orgCode === orgCode).length > 0; // 카드
      const isExistInvest = target.filter(item => item.industry === 'invest' && item.orgCode === orgCode).length > 0; // 증권
      // const isExistInsu = target.filter(item => item.industry === 'insu' && item.orgCode === orgCode).length > 0; // 보험
      // const isExistCapital = target.filter(item => item.industry === 'capital' && item.orgCode === orgCode).length > 0; // 할부금융
      const isExistEfin = target.filter(item => item.industry === 'efin' && item.orgCode === orgCode).length > 0; // 포인트머니
      const isExistTelecom = target.filter(item => item.industry === 'telecom' && item.orgCode === orgCode).length > 0; // 통신사
      if (isExistBank || isExistInvest || isExistEfin) {
        // 금융사 유형이 은행, 증권, 포인트머니의 경우
        // 적요 또는 거래메몰 전송요구 여부
        if (snsvAgree.includes('snsvAgree1')) {
          consent.is_consent_trans_memo = 'true';
        } else {
          consent.is_consent_trans_memo = 'false';
        }
      }

      // DEF_548 은행 정보제공자(선불카드 자산 연결) 자산 연결시 가맹점명/사업자등록번호 동의 여부가 노출되지 않는 오류
      if (isExistBank) {
        const sbCardInfo = that.selectedData.filter(
          item => item.industry === 'bank' && item.mdAstsTpc === '05' && item.orgCode === orgCode,
        );
        for (const sbCardData of sbCardInfo) {
          const selectedLen = that.selectedData.filter(data => data.orgCode === sbCardData.orgCode).length;
          const isSelected = selectedLen >= 0;
          const isAllAssetSelected =
            selectedLen >= 200 ? that.allAssetData.filter(data => data === sbCardData.orgCode).length > 0 : true;
          if ((isSelected && selectedLen < 200) || (selectedLen >= 200 && isAllAssetSelected)) {
            if (snsvAgree.includes('snsvAgree2')) {
              consent.is_consent_merchant_name_regno = 'true';
            } else {
              consent.is_consent_merchant_name_regno = 'false';
            }
          }
        }
      } else if (isExistCard || isExistEfin || isExistTelecom) {
        // 금융사 유형이 카드, 포인트머니, 통신사의 경우
        // 가맹점,사업자동륵번호 전송요구 여부
        if (snsvAgree.includes('snsvAgree2')) {
          consent.is_consent_merchant_name_regno = 'true';
        } else {
          consent.is_consent_merchant_name_regno = 'false';
        }
      }

      if (isExistEfin) {
        // 금융사 유형이 포인트머니의 경우
        // 상품카테고리 전송요구 여부
        if (snsvAgree.includes('snsvAgree3')) {
          consent.is_consent_trans_category = 'true';
        } else {
          consent.is_consent_trans_category = 'false';
        }
      }
    } else if (snsvAgree.includes('snsvAgree1')) {
      // 오픈뱅킹 간편전환
      // 금융사 유형이 은행, 증권의 경우
      // 적요 또는 거래메몰 전송요구 여부
      consent.is_consent_trans_memo = 'true';
      consent.is_consent_merchant_name_regno = 'false';
      consent.is_consent_trans_category = 'false';
    } else {
      consent.is_consent_trans_memo = 'false';
      consent.is_consent_merchant_name_regno = 'false';
      consent.is_consent_trans_category = 'false';
    }
    return consent;
  },
  // 삭제할 자산 전체선택 -> 자산정보 모두 삭제
  async fnBurConnCut(orgCode) {
    const input = {
      mdInfDlCstAgYn: 'Y',
      orgCodeList: [],
    };

    input.orgCodeList.push(orgCode);

    const result = await burConnCut(input);

    window.$nuxt.$log.log('기관 연결 해제 result ::', result);

    if (result?.success) {
      // 연결 해제에 성공한 기관명을 토스트 메시지에 세팅
      setCacheData(this.DELETE_ASSET_YN, 'Y');
      if (commonUtil.isMobileApp()) {
        window.$nuxt.$ongobridge.closeCurBrowser();
      } else {
        const deleteAssetBackPage = await getCacheData(this.DELETE_ASSET_BACK_PAGE);
        window.$nuxt.$router.push(deleteAssetBackPage);
      }
    }
  },
  /**
   * 금융인증서 초기화 시 파라미터
   * @param that context
   * @param nonceListByOrgCode 기관별 Nonce 목록
   * @param uniqVal 회원별고유식별자
   * @param _sign 서명함수
   * @returns {{fail(*): void, clientType: string, apiKey: (string), orgCode: (string), success(): void, useAutoConnInfo: boolean, clientOrigin: string, uniqVal, lang: string}}
   */
  getFinCertInitParam(that, nonceListByOrgCode, uniqVal, _sign) {
    const useAutoConnInfo = true;
    let clientType = '';
    let clientOrigin = '';
    const deviceCode = appUtill.getAppInfo('mblOsDvC');
    if (deviceCode === '01') {
      clientType = 'ANDROID';
      clientOrigin = 'net.ib.android.smcard';
    } else if (deviceCode === '02') {
      clientType = 'IOS';
      clientOrigin = 'com.samsungCard.samsungCard';
    }
    return {
      orgCode: commonUtil.isProd() ? 'RM00120000' : 'DF20500000',
      apiKey: commonUtil.isProd() ? 'd5439804-24a3-4a2c-a55f-e44ed3bbe9c1' : '9b0002b2-0ee4-4f46-9f9f-53020f996f3b',
      lang: 'kor',
      uniqVal,
      clientOrigin,
      clientType,
      useAutoConnInfo,
      async success() {
        that.$log.log('init 성공');
        const mdFinCertAutoConnInfo = await getLocalStorageData('MD_FINCERT_AUTO_CONN_INFO');
        if (mdFinCertAutoConnInfo !== null && mdFinCertAutoConnInfo !== '') {
          // eslint-disable-next-line no-undef
          FinCert.Sdk.setAutoConnInfo({
            autoConnInfo: mdFinCertAutoConnInfo,
            success(result) {
              that.$log.log('자동연결 정보 재생성 필요 여부 : ' + result.needAutoConnInfo);
              that.$log.log('자동연결 여부 : ' + result.isConnected);
              if (result.needAutoConnInfo) {
                // 자동연결 정보 재생성 필요
                // eslint-disable-next-line no-undef
                FinCert.Sdk.makeAutoConnInfo({
                  success(result) {
                    setLocalStorageData('MD_FINCERT_AUTO_CONN_INFO', result.autoConnInfo);
                    // eslint-disable-next-line no-undef
                    FinCert.Sdk.setAutoConnInfo({
                      autoConnInfo: result.autoConnInfo,
                      success(result2) {
                        that.$log.log('자동연결 정보 재생성 필요 여부 : ' + result2.needAutoConnInfo);
                        that.$log.log('자동연결 여부 : ' + result2.isConnected);
                        _sign(nonceListByOrgCode);
                      },
                      fail(error) {
                        that.$log.log(error.code + ' : ' + error.message);
                      },
                    });
                  },
                  fail(error) {
                    that.$log.log(error.code + ' : ' + error.message);
                  },
                });
              } else {
                _sign(nonceListByOrgCode);
              }
            },
            fail(error) {
              that.$log.log(error.code + ' : ' + error.message);
            },
          });
        } else {
          // eslint-disable-next-line no-undef
          FinCert.Sdk.makeAutoConnInfo({
            success(result) {
              setLocalStorageData('MD_FINCERT_AUTO_CONN_INFO', result.autoConnInfo);
              // eslint-disable-next-line no-undef
              FinCert.Sdk.setAutoConnInfo({
                autoConnInfo: result.autoConnInfo,
                success(result2) {
                  that.$log.log('자동연결 정보 재생성 필요 여부 : ' + result2.needAutoConnInfo);
                  that.$log.log('자동연결 여부 : ' + result2.isConnected);
                  if (result.needAutoConnInfo) {
                    // 자동연결 정보 재생성 필요
                    // eslint-disable-next-line no-undef
                    FinCert.Sdk.makeAutoConnInfo({
                      success(result) {
                        setLocalStorageData('MD_FINCERT_AUTO_CONN_INFO', result.autoConnInfo);
                        // eslint-disable-next-line no-undef
                        FinCert.Sdk.setAutoConnInfo({
                          autoConnInfo: mdFinCertAutoConnInfo,
                          success(result2) {
                            that.$log.log('자동연결 정보 재생성 필요 여부 : ' + result2.needAutoConnInfo);
                            that.$log.log('자동연결 여부 : ' + result2.isConnected);
                            _sign(nonceListByOrgCode);
                          },
                          fail(error) {
                            that.$log.log(error.code + ' : ' + error.message);
                          },
                        });
                      },
                      fail(error) {
                        that.$log.log(error.code + ' : ' + error.message);
                      },
                    });
                  } else {
                    _sign(nonceListByOrgCode);
                  }
                },
                fail(error) {
                  that.$log.log(error.code + ' : ' + error.message);
                },
              });
            },
            fail(error) {
              that.$log.log(error.code + ' : ' + error.message);
            },
          });
        }
      },
      fail(ErrorObject) {
        that.$log.log(ErrorObject);
      },
    };
  },
  async deleteAssetProcess(orgCode, backPage) {
    let sheetOpen = false;

    // 연결기관상세정보조회 API 호출
    const input = {};
    input.orgCode = orgCode;
    const result = await connBurDtlInfInqr(input);
    window.$nuxt.$log.log(`fnDelAsstListInqr result::`, result);
    if (result?.success) {
      if (result.payload.connBurDetailSubVOList?.length === 1 || result.payload.connBurDetailSubVOList?.length >= 200) {
        // 개별 자산 삭제_자산 1개_자산_200개 이상_전체 삭제 User Flow - 자산관리 기획서
        sheetOpen = true;
      }
    }
    return sheetOpen;
  },
  /**
   * 개별 자산 삭제 Process로 이동
   * @param orgCode 기관코드
   * @param backPage return될 페이지
   */
  moveDeleteAssetProcess(orgCode, backPage) {
    if (backPage) {
      setCacheData(this.DELETE_ASSET_BACK_PAGE, backPage);
    }
    if (commonUtil.isMobileApp()) {
      const params = {
        url: toPfmUrl('/mydata/join/UPFMJO0301M0?deleteYn=Y&orgCode=' + orgCode + '&deleteAssetBackPage=' + backPage),
        headerYn: 'N',
      };
      const openNewBrowser = {
        bridgeParams: params,
      };
      window.$nuxt.$ongobridge.openNewBrowser(openNewBrowser.bridgeParams, callbackData => {
        window.$nuxt.$log.log('callbackData: ', callbackData);
      });
    } else {
      window.$nuxt.$router.push({
        name: 'mydata/join/UPFMJO0301M0',
        query: { deleteYn: 'Y', orgCode },
      });
    }
  },
  async goToNextPage(url, queryData, cacheInfo, isObject, isRouterPush) {
    let targetUrl = url;
    const shutdownSrvList = ['UPFMJO0102M0', 'UPFMJO0201M0', 'UPFMJO0801M0', 'UPFMMA0401M0', 'UPFMMA0901M0'];
    for (const shutdownSrv of shutdownSrvList) {
      if (url.includes(shutdownSrv)) {
        // 에러 메시지 관련
        const shutdownMsgData = {
          id: 'shutdownMsgData',
          wrapStyleClass: 'message-dialog error',
          iconName: 'icon-error-caution3',
          buttons: [
            {
              btnId: 'confirm',
              btnStyleClass: 'primary',
              btnText: '확인',
              eventName: 'close',
            },
          ],
        };

        // 서비스 중단 관련 코드 추가
        // 백오피스에서 {shutdownSrv}_A / {shutdownSrv}_I 아이디로 서비스 차단가능
        const res = await inqrSrnActvPly({ srnId: shutdownSrv });
        if (res && res.success && res.result?.actv === false) {
          const message = res.result?.message;
          // 메시지 처리
          if (message && message !== '') {
            shutdownMsgData.contentText = message;
          } else {
            shutdownMsgData.msgCode = 'EBPFM0086'; // 일시적인 오류가 발생했어요.<br />잠시 후 다시 시도해주세요.
          }
          window.$nuxt.$modal.messageDialogData(shutdownMsgData); // dialog open
          return;
        }
      }
    }

    if (queryData) {
      if (Object.entries(queryData)?.length > 0) {
        targetUrl +=
          '?' +
          Object.entries(queryData)
            .map(e => e.join('='))
            .join('&');
      }
    }
    window.$nuxt.$log.log('targetUrl: ', targetUrl);
    if (cacheInfo) {
      if (isObject) {
        setCacheData(cacheInfo.key, cacheInfo.value, true, true);
      } else {
        setCacheData(cacheInfo.key, cacheInfo.value);
      }
    }
    if (isRouterPush) {
      window.$nuxt.$router.push(targetUrl);
    } else if (commonUtil.isMobileApp()) {
      const params = {
        url: toPfmUrl(targetUrl),
        headerYn: 'N',
      };
      const openNewBrowser = {
        bridgeParams: params,
      };
      window.$nuxt.$log.log('openNewBrowser: ', openNewBrowser);
      window.$nuxt.$ongobridge.openNewBrowser(openNewBrowser.bridgeParams, callbackData => {
        window.$nuxt.$log.log('callbackData: ', callbackData);
      });
    } else {
      window.$nuxt.$router.push(targetUrl);
    }
  },
  goMA0601(orgCode) {
    if (commonUtil.isMobileApp()) {
      const params = {
        url: toPfmUrl('/mydata/asset/UPFMMA0601M0?orgCode=' + orgCode),
        headerYn: 'N',
      };
      const openNewBrowser = {
        bridgeParams: params,
      };
      window.$nuxt.$ongobridge.openNewBrowser(openNewBrowser.bridgeParams, callbackData => {
        window.$nuxt.$log.log('callbackData: ', callbackData);
      });
    } else {
      window.$nuxt.$router.push({
        name: 'mydata/asset/UPFMMA0601M0',
        query: { orgCode },
      });
    }
  },
  // 기능 On/Off 데이터 호출
  async getFuncOnOffData() {
    // 기능 On/Off API
    let funcOnOffList = [];
    try {
      const getFunctionOnOffRes = await getFunctionOnOff();
      window.$nuxt.$log.log('[마이데이터유틸-functionOnOff] 기능 On/Off API called');
      if (getFunctionOnOffRes?.success) {
        // 호출 성공
        funcOnOffList = getFunctionOnOffRes?.payload?.contents;
        setCacheData(this.MD_FUNC_ON_OFF_LIST, funcOnOffList, true, true);
      } else {
        funcOnOffList = [];
        setCacheData(this.MD_FUNC_ON_OFF_LIST, [], true, true);
      }
    } catch (e) {
      window.$nuxt.$log.log('[마이데이터유틸-functionOnOff] 기능 On/Off API error');
    }
    return funcOnOffList;
  },
  /**
   * 특정 기능숨김영역코드에 대한 개인화된 On/Off 설정값 조회
   * 개인화 필드: BIZPARTY번호 (BIZPRT_NO)
   *
   * @param fncHidTeryCArr 기능숨김영역코드배열
   * @return boolean 기능On/Off 값 (true, false)
   */
  async getFuncOnOff(fncHidTeryCArr) {
    let funcOnOffList = (await getCacheData(this.MD_FUNC_ON_OFF_LIST, true, true)) ?? [];
    if (!funcOnOffList || funcOnOffList?.length === 0) {
      // 데이터 주입이 되지 않은 경우
      funcOnOffList = await this.getFuncOnOffData();
    }
    const items = {};
    for (const item of fncHidTeryCArr) {
      items[item] = funcOnOffList?.filter(data => data.fnc_hid_tery_c === item && data.expsr_yn === 'Y')?.length > 0;
    }
    return items;
  },
  getMainComponentName(mdMaiSrnTeryC) {
    let componentName = '';
    if (mdMaiSrnTeryC === '01') {
      // 계좌
      componentName = 'UPFMMA0101C3';
    } else if (mdMaiSrnTeryC === '02') {
      // 소비
      componentName = 'UPFMMA0101C4';
    } else if (mdMaiSrnTeryC === '03') {
      // 투자
      componentName = 'UPFMMA0101C5';
    } else if (mdMaiSrnTeryC === '04') {
      // 보험
      componentName = 'UPFMMA0101C6';
    } else if (mdMaiSrnTeryC === '05') {
      // 대출
      componentName = 'UPFMMA0101C7';
    } else if (mdMaiSrnTeryC === '06') {
      // 연금
      componentName = 'UPFMMA0101C8';
    } else if (mdMaiSrnTeryC === '07') {
      // 포인트/머니
      componentName = 'UPFMMA0101C9';
    } else if (mdMaiSrnTeryC === '08') {
      // 건강
      componentName = 'UPFMMA0101C12';
    } else if (mdMaiSrnTeryC === '51') {
      // 신용점수
      componentName = 'UPFMMA0101C10';
    } else if (mdMaiSrnTeryC === '52') {
      // 환전지갑
      componentName = 'UPFMMA0101C10';
    } else if (mdMaiSrnTeryC === '53') {
      // 자동차
      componentName = 'UPFMMA0101C11';
    } else if (mdMaiSrnTeryC === '54') {
      // 부동산
      componentName = 'UPFMMA0101C11';
    } else if (mdMaiSrnTeryC === '55') {
      // 기타자산
      componentName = 'UPFMMA0101C13';
    }
    return componentName;
  },
  getMdMaiSrnTeryC(componentName) {
    let mdMaiSrnTeryC = '';
    if (componentName === 'UPFMMA0101C3') {
      // 계좌
      mdMaiSrnTeryC = '01';
    } else if (componentName === 'UPFMMA0101C4') {
      // 소비
      mdMaiSrnTeryC = '02';
    } else if (componentName === 'UPFMMA0101C5') {
      // 투자
      mdMaiSrnTeryC = '03';
    } else if (componentName === 'UPFMMA0101C6') {
      // 보험
      mdMaiSrnTeryC = '04';
    } else if (componentName === 'UPFMMA0101C7') {
      // 대출
      mdMaiSrnTeryC = '05';
    } else if (componentName === 'UPFMMA0101C8') {
      // 연금
      mdMaiSrnTeryC = '06';
    } else if (componentName === 'UPFMMA0101C9') {
      // 포인트/머니
      mdMaiSrnTeryC = '07';
    } else if (componentName === 'UPFMMA0101C12') {
      // 건강
      mdMaiSrnTeryC = '08';
    } else if (componentName === 'UPFMMA0101C10') {
      // 신용점수
      mdMaiSrnTeryC = '51';
    } else if (componentName === 'UPFMMA0101C10') {
      // 환전지갑
      mdMaiSrnTeryC = '52';
    } else if (componentName === 'UPFMMA0101C11') {
      // 자동차
      mdMaiSrnTeryC = '53';
    } else if (componentName === 'UPFMMA0101C11') {
      // 부동산
      mdMaiSrnTeryC = '54';
    } else if (componentName === 'UPFMMA0101C13') {
      // 기타자산
      mdMaiSrnTeryC = '55';
    }
    return mdMaiSrnTeryC;
  },
  async mydataSignCallback() {
    const authCertTxId = await getCacheData(this.AUTH_CERT_TX_ID);
    const authSignTxId = await getCacheData(this.AUTH_SIGN_TX_ID);
    const mdCertaDvC = await getCacheData(this.MD_CERTA_DV_C);

    const signResultRes = await privateSignResult({
      certTxId: authCertTxId,
      signTxId: authSignTxId,
      mdCertaDvC,
    });
    if (signResultRes.success) {
      // signed_consent 목록
      // signed_consent 개수
      // const signedConsentCnt = signResultRes.payload?.signedConsentCnt;
      const signedData = signResultRes.payload?.privateSignResultSubVOList;
      this.goNextPage(signedData);
    }
  },
  async goNextPage(signedData) {
    const authFirstYn = await getCacheData(this.MD_AUTH_FIRST_YN);
    const privateSignParams = await getCacheData(this.PRIVATE_SIGN_PARAMS, true, true);
    privateSignParams.params.signedDataList = signedData;
    // 계좌명 key 'mdAsstNm' 으로 조회한 데이터 unescape 처리
    privateSignParams.params.uAuthAsstListQVOList = this.unescapeMyData(
      privateSignParams.params.uAuthAsstListQVOList,
      'mdAsstNm',
    );
    const nextPageData = privateSignParams.params;
    window.$nuxt.$log.log('[마이데이터유틸-goNextPage] nextPageData ::', nextPageData);
    let url = '';
    let uAuthKey;
    let cacheData;
    if (authFirstYn === 'Y') {
      // 1차인증의 경우
      url = '/mydata/join/UPFMJO0401M0';
      const putUAuthInfoRes = await putUAuthInfo({
        uAuthInfo: JSON.stringify(nextPageData),
      });
      uAuthKey = putUAuthInfoRes.payload?.uAuthKey;
      cacheData = {
        key: this.U_AUTH_KEY,
        value: {
          uAuthKey,
        },
      };
    } else {
      // 2차인증의 경우
      url = '/mydata/join/UPFMJO0701M0';
      const putUAuthInfoRes = await putUAuthInfo({
        uAuthInfo: JSON.stringify(nextPageData),
      });
      uAuthKey = putUAuthInfoRes.payload?.uAuthKey;
      cacheData = {
        key: this.U_AUTH_KEY,
        value: {
          uAuthKey,
        },
      };
    }
    const otherPageMoveYn = window.$nuxt.$route?.query?.otherPageMoveYn;
    if (otherPageMoveYn === 'Y') {
      setCacheData('MD_ASSET_' + privateSignParams.params?.orgData[0]?.orgCode + '_UPDATE', 'Y');
    }

    this.goToNextPage(url, null, cacheData, true, true);
  },
  numberToKorean(number, isFloor) {
    let inputNumber = number < 0 ? false : number;
    const unitWords = [' ', '만 ', '억 ', '조 ', '경 '];
    const splitUnit = 10000;
    const splitCount = unitWords.length;
    const resultArray = [];
    let resultString = '';
    // 만 단위 내림 처리
    if (isFloor) {
      inputNumber = stringUtil.cutUnit(inputNumber, 4) * 10000;
    }
    for (let i = 0; i < splitCount; i++) {
      let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }
    for (let i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString = String(addComma(resultArray[i])) + unitWords[i] + resultString;
    }
    return resultString;
  },
  wonInsertSpaceComma(won) {
    window.$nuxt.$log.log('WonInsertSpaceComma():' + won);
    const amountParts =
      won
        ?.split('원')[0]
        ?.split(/([경조억만])/)
        .filter(part => part !== '') ?? [];
    let result = '';
    for (let i = 0; i < amountParts.length; i += 2) {
      const num = amountParts[i];
      const unit = amountParts[i + 1];
      if (unit) {
        if (i > 1) {
          result += ' ';
        }
        result += Number(num)?.toLocaleString() + unit;
      }
    }

    if (amountParts.length === 0) {
      result += '0';
    }

    window.$nuxt.$log.log('result:' + result);
    return result + '원';
  },
  // 배너 모듈 셋팅
  setBnnrModule(bnnr, bannerHtml, lottieHtml) {
    /*
     *  [*예제 정보*]
     *  {
        "psnzCntsId" : "009",
              "seq" : "3",
              "screenPage" : "1",
              "bnrType" : "3",
              "moduleType" : "1",
        "bnrTit" : "이번 달에 만기 예정인 예적금은",
              "bnrDesc" : "%param1%이에요",
              "imgType" : "",
              "imgURL" : "",
              "lottieNm" : "04_chick",
              "btnNm" : "",
              "clickURL" : ""
          },
     */
    try {
      let html = '';

      if (bnnr.imgBnnrUseYn === 'N') {
        html = lottieHtml;
        bnnr.wcmsContents = lottieHtml;
      } else {
        html = bannerHtml;
        bnnr.wcmsContents = bannerHtml;
      }

      const parser = new DOMParser();
      const tags = parser.parseFromString(html, 'text/html');

      // bnnr.psnzExpsrMsgCn data null 이면 한줄로 표기(p tag 영역 display none, h2 tag 영역만 표기)
      if (!bnnr.psnzExpsrMsgCn) {
        tags.querySelector('.text-box p').style.display = 'none';
      }
      tags.querySelector('.text-box p').innerHTML = bnnr.psnzExpsrMsgCn;
      tags.querySelector('.text-box h2').innerHTML = bnnr.psnzExpsrMsgAtcCn;

      if (bnnr.imgBnnrUseYn === 'N') {
        bnnr.wcmsJsonUrl = `/monimo/lottie/${bnnr.cntsImgUrl}.json`;
        bnnr.wcmsContents = tags.documentElement.outerHTML;
        window.$nuxt.$log.log(`WCMS BANNER TEST`, bnnr.wcmsJsonUrl);
      } else {
        bnnr.imgUrl = bnnr.cntsImgUrl;
        bnnr.wcmsContents = tags.documentElement.outerHTML;
      }
    } catch (error) {
      window.$nuxt.$log.log(error);
      window.$nuxt.$log.log(`[개인화배너 Error]`, bnnr);
    }
  },
  /*
   * Collect 요청 후 상태 체크하여 완료/미완료 상태를 Return
   */
  async collectCmplReq(param, isPullToRefresh) {
    // 수집 상태 체크
    const collectPrevStatus = await getCollectStatus({
      transId: '',
    });

    let transId = '';

    if (collectPrevStatus?.success) {
      // 수집 중 인 경우
      if (collectPrevStatus?.payload?.responseCode === '202') {
        transId = collectPrevStatus?.payload?.transId;
      }

      if (isPullToRefresh) {
        if (collectPrevStatus?.payload?.responseCode === '404') {
          // 수집 중이 아닌 경우
          const collectInfo = {
            orgTag: param?.orgTag ?? 'all',
            apiTag: param?.apiTag ?? 'all',
            fromDate: param?.fromDate ?? DateUtil.subtract(new Date(), 1, 'M', DateUtil.FORMAT.YYYYMMDD),
            toDate: param?.toDate ?? DateUtil.now(DateUtil.FORMAT.YYYYMMDD),
            xApiType: param?.xApiType ?? 'user-refresh',
          };
          const collectRes = await collect(collectInfo);
          if (collectRes?.success) {
            transId = collectRes?.payload?.transId;
          }
        }
      }
    }

    // 1) 수집 중 상태이고, 2) Trans Id 가 존재하는 경우 Interval
    if (this.getIfNull(transId, '') !== '') {
      let interValCnt = 0;
      const interval = setInterval(async () => {
        // 수집 상태 재요청
        const reCollectStatus = await getCollectStatus({ transId });
        interValCnt++;

        if (reCollectStatus.success) {
          transId = reCollectStatus.payload.transId;

          // 수집 상태 재요청 시 transId 가 없거나, 30회 호출 시 Interval 제거
          if (this.getIfNull(transId, '') === '' || interValCnt === 30) {
            clearInterval(interval);
            return false;
          } else if (reCollectStatus?.payload?.responseCode === '302') {
            // 수집 완료 시
            clearInterval(interval);
            return true;
          } else if (reCollectStatus?.payload?.responseCode === '202') {
            // 수집 중
          } else {
            clearInterval(interval);
            return false;
          }
        } else if (interValCnt === 30) {
          clearInterval(interval);
          return false;
        } else {
          // 수집요청 실패할 경우
          clearInterval(interval);
          return false;
        }
      }, 1000);
    }

    return false;
  },
  /**
   * 마이데이터 Set Cache Data
   * @param signedData
   * @param that
   * @returns {{requestType: key(String), data(Object) }}
   */
  async setStorageData(key, data, dueDate) {
    // Object로 들어왔을 경우 string으로 변환
    if (TypeUtil.isObject(data)) {
      data = encodeURIComponent(JSON.stringify(data));
    }
    if (commonUtil.isMobileApp()) {
      await window.$nuxt.context.$ongobridge.setEncLocalData({
        key,
        value: data,
        dueDate,
      });
    } else {
      setLocalStorageData(key, data, true, true);
    }
  },
  /**
   * 마이데이터 Get Cache Data
   * @param signedData
   * @param that
   * @returns {{requestType: key(String), data(Object), responseType: Object }}
   */
  async getStorageData(key) {
    let storageData;

    if (commonUtil.isMobileApp()) {
      const encData = await window.$nuxt.context.$ongobridge.getEncLocalData({ key });
      storageData = encData?.data?.value;
    } else {
      storageData = await getLocalStorageData(key, true, true);
    }

    if (this.jsonChk(storageData)) {
      return JSON.parse(decodeURIComponent(storageData));
    } else {
      return storageData;
    }
  },
  jsonChk(data) {
    try {
      const parseData = JSON.parse(decodeURIComponent(data));
      return typeof parseData === 'object';
    } catch (e) {
      return false;
    }
  },
  async collectReq(param) {
    let success = false;
    const collectPrevStatus = await getCollectStatus({
      transId: '',
    });
    const resCurDate = await this.resCurDate();
    if (collectPrevStatus?.success) {
      if (collectPrevStatus?.payload?.responseCode === '404' || collectPrevStatus?.payload?.responseCode === '400') {
        const params = {
          orgTag: param?.orgTag ?? 'all',
          apiTag: param?.apiTag ?? 'all',
          fromDate: param?.fromDate ?? DateUtil.parseDate(resCurDate).subtract(1, 'M').format('YYYYMMDD'),
          toDate: param?.toDate ?? resCurDate,
          xApiType: param?.xApiType ?? 'user-refresh',
        };
        const collectRes = await collect(params);
        if (collectRes?.success) {
          // this.testText += '통합수집요청 transId:' + collectRes?.payload?.transId + '<br>';
          if (collectRes?.payload?.transId) {
            // this.testText += 'Collect Status API call<br>';
            const collectStatus = await getCollectStatus({
              transId: collectRes?.payload?.transId,
            });
            if (collectStatus?.success) {
              const response = collectStatus?.payload.responseCode;
              // this.testText += 'collect status code: ' + response + '<br>';
              if (response === '302') {
                success = true;
              }
            }
          }
        }
      } else if (collectPrevStatus?.payload?.transId) {
        if (collectPrevStatus?.success) {
          const response = collectPrevStatus?.payload.responseCode;
          if (response === '302') {
            success = true;
          }
        }
      }
    }
    return success;
  },
  // 기관 별 수집 요청(1개월)
  async collectByOrgMms1(params) {
    const inqrYm = params.inqrYm;
    const resCurDate = await this.resCurDate();
    const mms12b = DateUtil.parseDate(resCurDate).subtract(12, 'M').format('YYYYMM');
    const csmCollect = (await getLocalStorageData(this.MD_COLLECT_ + params.orgCode, true, true)) ?? [];
    window.$nuxt.$log.log(csmCollect);
    if (inqrYm <= mms12b) {
      if (csmCollect.length > 0) {
        // 저장된 기관이 있는 경우 수집 날짜 체크
        if (csmCollect.filter(data => data === inqrYm).length > 0) {
          return;
        }
      }
      const minDate = DateUtil.parseDate(resCurDate).subtract(5, 'year').format('YYYYMMDD');
      const reqDate = DateUtil.formatDate(DateUtil.add(minDate, 1, 'd'), 'YYYYMMDD');
      let dt = '01';
      if (reqDate.substr(0, 6) === inqrYm) {
        dt = reqDate.substr(6, 2);
      }
      const fromDate = inqrYm + dt;
      const toDate = inqrYm + new Date(inqrYm.substr(0, 4), inqrYm.substr(4, 2), 0).getDate();
      const collectRes = await collectByOrg({
        orgCode: params.orgCode,
        apiTag: 'all',
        fromDate,
        toDate,
        xApiType: 'user-search', // user-search - 조회 기준 기간 ( 5년 : 자산 거래내역 조회 처리 시)
      });
      if (collectRes?.success) {
        const payload = collectRes.payload ?? [];
        if (payload.length > 0) {
          if (payload[0].rspCode === '00000') {
            csmCollect.push(inqrYm);
            setLocalStorageData(this.MD_COLLECT_ + params.orgCode, csmCollect, true, true);
          }
        }
      }
    }
  },
  // 업권 별 수집요청(1개월)
  async collectReqOrgTagMms1(param) {
    const collectReq = (await getCacheData(this.MD_COMMON_COLLECT_REQ)) ?? '';
    window.$nuxt.$log.log(collectReq);
    if (collectReq === 'start') {
      const inqrYm = param.inqrYm;
      const orgTag = param.orgTag ?? 'all';
      const apiTag = param.apiTag ?? 'all';
      const resCurDate = await this.resCurDate();
      const mms12b = DateUtil.parseDate(resCurDate).subtract(12, 'M').format('YYYYMM');
      const csmCollect = (await getLocalStorageData(this.MD_COLLECT_ + param.orgTag, true, true)) ?? [];
      if (inqrYm <= mms12b) {
        window.$nuxt.$log.log(csmCollect);
        if (csmCollect.length > 0) {
          // 저장된 기관이 있는 경우 수집 날짜 체크
          if (csmCollect.filter(data => data === inqrYm).length > 0) {
            // 저장된 업권이 있는 경우 수집 날짜 체크
            setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
            return 'skip';
          }
        }
        const collectPrevStatus = await getCollectStatus({
          transId: '',
        });
        if (collectPrevStatus?.success) {
          if (collectPrevStatus?.payload?.responseCode === '404') {
            const minDate = DateUtil.parseDate(resCurDate).subtract(5, 'year').format('YYYYMMDD');
            const reqDate = DateUtil.formatDate(DateUtil.add(minDate, 1, 'd'), 'YYYYMMDD');
            let dt = '01';
            if (reqDate.substr(0, 6) === inqrYm) {
              dt = reqDate.substr(6, 2);
            }
            const fromDate = inqrYm + dt;
            const toDate = inqrYm + new Date(inqrYm.substr(0, 4), inqrYm.substr(4, 2), 0).getDate();
            const params = {
              orgTag,
              apiTag,
              fromDate,
              toDate,
              xApiType: 'user-search',
            };
            const collectRes = await collect(params);
            if (collectRes?.success) {
              // this.testText += '통합수집요청 transId:' + collectRes?.payload?.transId + '<br>';
              if (collectRes?.payload?.transId) {
                // this.testText += 'Collect Status API call<br>';
                const collectStatus = await getCollectStatus({
                  transId: collectRes?.payload?.transId,
                });
                if (collectStatus?.success) {
                  const response = collectStatus?.payload.responseCode;
                  // this.testText += 'collect status code: ' + response + '<br>';
                  if (response === '302') {
                    setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
                    window.$nuxt.$log.log('response :', response);
                    csmCollect.push(inqrYm);
                    setLocalStorageData(this.MD_COLLECT_ + param.orgTag, csmCollect, true, true);
                    return 'scs';
                  }
                }
              }
            }
          } else if (collectPrevStatus?.payload?.transId) {
            window.$nuxt.$log.log('responseCode : ', collectPrevStatus?.payload.responseCode);
            if (collectPrevStatus?.success) {
              const response = collectPrevStatus?.payload.responseCode;
              if (response === '302') {
                setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
                csmCollect.push(inqrYm);
                setLocalStorageData(this.MD_COLLECT_ + param.orgTag, csmCollect, true, true);
                return 'scs';
              }
            }
          }
        }
      } else {
        return 'skip';
      }
      return 'prg';
    }
  },
  // 기관별 수집요청(3개월)
  async collectReqOrgListMms3(param) {
    const collectReq = (await getCacheData(this.MD_COMMON_COLLECT_REQ)) ?? '';
    if (collectReq === 'start') {
      const strtmm = param.strtmm;
      const endmm = param.endmm;
      const mmList = [
        DateUtil.parseDate(endmm).format('YYYYMM'),
        DateUtil.parseDate(endmm).subtract(1, 'M').format('YYYYMM'),
        DateUtil.parseDate(endmm).subtract(2, 'M').format('YYYYMM'),
      ];
      const resCurDate = await this.resCurDate();
      let mmsBf = DateUtil.parseDate(resCurDate).subtract(12, 'M').format('YYYYMM');
      if (param.orgTag === 'bills') {
        // 청구서 같은 경우 최초 자산연결시 현재월 +1월 ~ -12월 로 수집함
        mmsBf = DateUtil.parseDate(resCurDate).subtract(11, 'M').format('YYYYMM');
      }
      let csmCollect = (await getLocalStorageData(this.MD_COLLECT_ + param.orgTag, true, true)) ?? [];
      window.$nuxt.$log.log(csmCollect);
      // 수집요청이 기간이 최근 11개월 전 이거나 캐시에 저장된 수집요청이 있으면 skip
      if (
        mmList.filter(data => data <= mmsBf).length === 0 ||
        (csmCollect.length > 0 && mmList.filter(data => !csmCollect.includes(data)).length === 0)
      ) {
        setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
        return 'skip';
      } else {
        // 수집요청 시작
        const collectPrevStatus = await getCollectStatusLoading({
          transId: '',
        });
        if (collectPrevStatus?.success) {
          if (collectPrevStatus?.payload?.responseCode === '404') {
            const minDate = DateUtil.parseDate(resCurDate).subtract(5, 'year').format('YYYYMMDD');
            const reqDate = DateUtil.formatDate(DateUtil.add(minDate, 1, 'd'), 'YYYYMMDD');
            let dt = '01';
            if (reqDate.substr(0, 6) === strtmm) {
              dt = reqDate.substr(6, 2);
            }
            const fromDate = strtmm + dt;
            const yyyy = endmm.substr(0, 4);
            const mm = endmm.substr(4, 2);
            const toDate = yyyy + mm + new Date(yyyy, mm, 0).getDate();
            const params = {
              orgList: param.orgList,
              fromDate,
              toDate,
              xApiType: 'user-search',
            };
            const collectRes = await collectByOrgList(params);
            if (collectRes?.success) {
              // this.testText += '통합수집요청 transId:' + collectRes?.payload?.transId + '<br>';
              if (collectRes?.payload?.transId) {
                // this.testText += 'Collect Status API call<br>';
                const collectStatus = await getCollectStatusLoading({
                  transId: collectRes?.payload?.transId,
                });
                if (collectStatus?.success) {
                  const response = collectStatus?.payload.responseCode;
                  // this.testText += 'collect status code: ' + response + '<br>';
                  if (response === '302') {
                    setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
                    csmCollect = csmCollect.concat(mmList);
                    const dupCsmCollect = csmCollect.filter((v, i) => csmCollect.indexOf(v) === i).sort();
                    setLocalStorageData(this.MD_COLLECT_ + param.orgTag, dupCsmCollect, true, true);
                    return 'scs';
                  }
                }
              }
            }
          } else if (collectPrevStatus?.payload?.transId) {
            if (collectPrevStatus?.success) {
              const response = collectPrevStatus?.payload.responseCode;
              if (response === '302') {
                setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
                csmCollect = csmCollect.concat(mmList);
                const dupCsmCollect = csmCollect.filter((v, i) => csmCollect.indexOf(v) === i).sort();
                setLocalStorageData(this.MD_COLLECT_ + param.orgTag, dupCsmCollect, true, true);
                return 'scs';
              }
            }
          }
        }
      }
    }
    return 'prg';
  },
  // 기관목록 수집요청
  async collectReqByOrgList(param) {
    const collectReq = (await getCacheData(this.MD_COMMON_COLLECT_REQ)) ?? '';
    window.$nuxt.$log.log(collectReq);
    const csmCollect = (await getLocalStorageData(this.MD_COLLECT_ORG_LIST, true, true)) ?? [];
    window.$nuxt.$log.log(csmCollect);
    if (csmCollect.length > 0 && !param.refresh) {
      // 저장된 기관이 있는 경우 수집 날짜 체크
      if (csmCollect.filter(data => data === param.inqrYm).length > 0) {
        // 저장된 업권이 있는 경우 수집 날짜 체크
        setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
        return 'skip';
      }
    }
    if (collectReq === 'start') {
      const collectPrevStatus = await getCollectStatus({
        transId: '',
      });
      if (collectPrevStatus?.success) {
        if (collectPrevStatus?.payload?.responseCode === '404') {
          const collectRes = await collectByOrgList(param);
          if (collectRes?.success) {
            // this.testText += '통합수집요청 transId:' + collectRes?.payload?.transId + '<br>';
            if (collectRes?.payload?.transId) {
              // this.testText += 'Collect Status API call<br>';
              const collectStatus = await getCollectStatus({
                transId: collectRes?.payload?.transId,
              });
              if (collectStatus?.success) {
                const response = collectStatus?.payload.responseCode;
                // this.testText += 'collect status code: ' + response + '<br>';
                if (response === '302') {
                  setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
                  if (!param.refresh) {
                    csmCollect.push(param.inqrYm);
                    setLocalStorageData(this.MD_COLLECT_ORG_LIST, csmCollect, true, true);
                  }
                  window.$nuxt.$log.log('response :', response);
                  return 'scs';
                }
              }
            }
          }
        } else if (collectPrevStatus?.payload?.transId) {
          window.$nuxt.$log.log('responseCode : ', collectPrevStatus?.payload.responseCode);
          if (collectPrevStatus?.success) {
            const response = collectPrevStatus?.payload.responseCode;
            if (response === '302') {
              setCacheData(this.MD_COMMON_COLLECT_REQ, 'stop');
              if (!param.refresh) {
                csmCollect.push(param.inqrYm);
                setLocalStorageData(this.MD_COLLECT_ORG_LIST, csmCollect, true, true);
              }
              return 'scs';
            }
          }
        }
      }
      return 'prg';
    }
  },
  getMms12b(inqrYm) {
    return inqrYm <= DateUtil.parseDate(this.resCurDate()).subtract(12, 'M').format('YYYYMM');
  },
  async insuranceDataLoading(context) {
    const response = await astsBasInf();
    let errorYn = 'N';
    let mmAmt = 0;
    let slfYn = 'N';
    let sffYn = 'N';
    let insrInfList = []; // 계약자(피보험자=본인) + 피보험자(본인)
    let insrInfInsrpsList = []; // 계약자(피보험자!=본인)
    let insrCarInfList = [];
    let grnEndCnt = 0;
    if (response?.success) {
      const astsBasInfData = response.payload;
      mmAmt = Math.floor(astsBasInfData.mmAmt); // 이번달 보험료
      slfYn = astsBasInfData.slfYn; // 삼성생명 여부
      sffYn = astsBasInfData.sffYn; // 삼성화재 여부
      grnEndCnt = astsBasInfData.grnEndCnt; // 보장이 끝난 보험
    } else {
      errorYn = 'Y';
    }

    // 보험 목록
    const resInsrList = await insrInf();
    if (resInsrList?.success) {
      const resultList = resInsrList.payload.insrInfDVOList;
      if (resultList?.length > 0) {
        // 보험만기 뱃지 값 setting
        const today = await this.resCurDate();
        let diff = 0;
        let expDate = '';
        for (let i = 0; i < resultList?.length; i++) {
          if (resultList[i].expDate !== null) {
            expDate = DateUtil.formatDate(resultList[i].expDate, 'YYYYMMDD');
            diff = DateUtil.diff(expDate, today, 'd');
            resultList[i].expBadge = diff;
          }
        }

        // 내가 가입한 보험 목록(내가 피보험자 + 계약자==피보험자)
        insrInfList = resultList?.filter(
          item => item.insuredYn === 'Y' || (item.insuredYn === 'N' && item.insuredName === '본인'),
        );
        // 내가 가입해준 보험 목록(내가 계약자 && 계약자!=피보험자)
        insrInfInsrpsList = resultList?.filter(item => item.insuredYn === 'N' && item.insuredName !== '본인');
      }

      // 삼성기관인 insuType
      const ssInsuType = _.uniq(insrInfList.filter(data => data.ssYn === '0').map(data => data.insuType));
      const ssList = [];
      const etcList = [];
      insrInfList.forEach(item => {
        if (ssInsuType.includes(item.insuType)) {
          // 삼성 기관 담기
          ssList.push(item);
        } else {
          // 그 외 기관 담기
          etcList.push(item);
        }
      });
      insrInfList = ssList.concat(etcList);

      insrCarInfList = resInsrList.payload.carInsrInfDVOList;
    } else {
      errorYn = 'Y';
    }

    return {
      errorYn,
      mmAmt,
      slfYn,
      sffYn,
      insrInfList,
      insrInfInsrpsList,
      insrCarInfList,
      grnEndCnt,
    };
  },
  // 서버시간 조회
  async resCurDate() {
    const response = await selCurDate();
    if (response?.success) {
      return response.result;
    } else {
      return DateUtil.nowDate('YYYYMMDD');
    }
  },
  // 모니모 마케팅 동의여부 조회
  async getMrktAgYn() {
    const response = await getMrktAgYn();
    if (response?.success) {
      return response?.payload?.mrktAgYn || 'N';
    }
  },
  // 개인화배너 조회
  async getPsnzBnnr(params) {
    const response = await getPsnzBnnr(params);
    if (response?.success) {
      return response?.payload?.psnzBnnrList || [];
    } else {
      return [];
    }
  },
  // GHC 건강점수 산출 진행상태코드 저장
  setGhcHealthPrgStc(stc) {
    if (stc) {
      this.MD_GHC_HEALTH_PRG_STC = stc;
      const stcArr = Array.from(this.MD_GHC_HEALTH_PRG_STC_LIST);
      if (stcArr && !stcArr.includes(stc)) stcArr.push(stc);
      this.MD_GHC_HEALTH_PRG_STC_LIST = stcArr;
    }
  },
  // GHC 건강점수 산출 진행상태코드 초기화
  initGhcHealthPrgStc() {
    this.MD_GHC_HEALTH_PRG_STC = '';
    const stcArr = Array.from(this.MD_GHC_HEALTH_PRG_STC_LIST);
    if (stcArr && stcArr.length > 0) this.MD_GHC_HEALTH_PRG_STC_LIST = [];
  },
  /**
   * [개인화넛지] 개인화 배너 클릭 시, 이동 처리
   * @param item 개인화배너 정보
   */
  getPsnzBnnrDetail(item) {
    if (item?.lpgeUrlClsfC) {
      // 랜딩페이지URL분류코드 데이터가 내려와있는지 확인
      if (item.lpgeUrlClsfC === '01') {
        // 모니모 F/E로 랜딩
        this.goToNextPage(item.bnnrLinkUrl);
      } else if (item.lpgeUrlClsfC === '02') {
        // 삼성카드 개인홈 F/E로 랜딩
        const params = {
          url: toHppUrl(item.bnnrLinkUrl),
          headerYn: 'Y',
          optionYn: 'N',
          backYn: 'Y',
          iosToolbarYn: 'N',
        };
        window.$nuxt.$ongobridge.openNewBrowser(params);
      } else if (item.lpgeUrlClsfC === '03') {
        // 삼성생명으로 랜딩
        const params = {
          url: toSlfHmpgUrl(item.bnnrLinkUrl),
          mnmAflcmpC: 'SLF',
          headerYn: 'N', // 2024-09-10 생명 요청으로 헤더 삭제(이화수 프로님 요청)
          movingStepYn: 'Y',
        };
        window.$nuxt.$ongobridge.openNewBrowser(params);
      } else if (item.lpgeUrlClsfC === '04') {
        // 삼성증권으로 랜딩
        const params = {
          url: toSssUrl(item.bnnrLinkUrl),
          mnmAflcmpC: 'SSS',
          headerYn: 'N',
          movingStepYn: 'Y',
        };
        window.$nuxt.$ongobridge.openNewBrowser(params);
      } else if (item.lpgeUrlClsfC === '05') {
        // 삼성화재로 랜딩
        const params = {
          url: toSsfHmpgUrl(item.bnnrLinkUrl),
          mnmAflcmpC: 'SSF', // 화재
          headerYn: 'N', // 2024-09-10 화재 요청으로 헤더 삭제(차명진 프로님 요청)
          movingStepYn: 'Y',
        };
        window.$nuxt.$ongobridge.openNewBrowser(params);
      } else if (item.lpgeUrlClsfC === '08') {
        // 삼성생명 다이렉트로 랜딩
        const params = {
          url: toSlfDirctUrl(item.bnnrLinkUrl),
          mnmAflcmpC: 'SLF',
          headerYn: 'N', // 2024-09-10 생명 요청으로 헤더 삭제(이화수 프로님 요청)
          movingStepYn: 'Y',
        };
        window.$nuxt.$ongobridge.openNewBrowser(params);
      } else if (item.lpgeUrlClsfC === '09') {
        // 삼성화재 다이렉트로 랜딩
        const params = {
          url: toSsfDirctUrl(item.bnnrLinkUrl),
          mnmAflcmpC: 'SSF', // 화재
          headerYn: 'N', // 2024-09-10 화재 요청으로 헤더 삭제(차명진 프로님 요청)
          movingStepYn: 'Y',
        };
        window.$nuxt.$ongobridge.openNewBrowser(params);
      } else if (item.lpgeUrlClsfC === '99') {
        // openNative 네이티브 bridge 이용하여 랜딩
        window?.$nuxt?.$ongobridge?.openNative(JSON.parse(item.bnnrLinkUrl.replace(/\\/gi, '')));
      } else if (item.lpgeUrlClsfC === '07') {
        // 투데이아티클 상세페이지로 이동
        if (!item.bnnrLinkUrl) {
          return;
        }
        const index = item.bnnrLinkUrl.indexOf('TD'); // 트루데이터 컨텐츠 아이디 시작 지점 찾기.
        const trdtaCntsId = item.bnnrLinkUrl.substring(index, index + 10); // 트루데이터 컨텐츠 아이디 추출.
        const params = {
          url: item.bnnrLinkUrl,
          trdtaCntsId,
        };
        const baseUrl = '/truedata/today/UPFMTR0101M6';
        this.goToNextPage(baseUrl, params);
      } else {
        // 랜딩페이지URL분류코드가 미정의된 코드면 모니모 F/E로 랜딩
        this.goToNextPage(item.bnnrLinkUrl);
      }
    } else {
      // 랜딩페이지URL분류코드가 없으면 모니모 F/E로 랜딩
      this.goToNextPage(item.bnnrLinkUrl);
    }
  },

  // 모니모 메인(UPFMMA0101M0) 에서 사용하는 Storage 데이터 Clear
  mainStorageClear() {
    setCacheData(this.MYDATA_MAIN_REFRESH, null);
    setCacheData(this.DELETE_ASSET_BACK_PAGE, null);
    setCacheData(this.DELETE_ASSET_YN, null);
    setCacheData(this.MD_ASSET_UPDATE, null);
    setCacheData(this.MD_ASSET_DELAY_UPDATE, null);
    setCacheData(this.MD_UPDATE, null);
    setCacheData(this.MD_SECESSION, null);
    setCacheData(this.MD_OPBK_UPDATE, null);
  },

  // 개인화 배너 태깅
  psnzBnnrTagging(expsrSrnIvtrId, taggingYn) {
    window.$nuxt.$log.log('expsrSrnIvtrId :', expsrSrnIvtrId);
    window.$nuxt.$log.log('taggingYn :', taggingYn);
    const psnzBnnrList = this.MD_PSNZ_BNNR_TAGGING_DATA;
    window.$nuxt.$log.log('psnzBnnrList :', psnzBnnrList);
    if (psnzBnnrList.length > 0) {
      psnzBnnrList.forEach(data => {
        if (data.expsrSrnIvtrId === expsrSrnIvtrId) {
          data.tagging = taggingYn;
        }
      });
      this.MD_PSNZ_BNNR_TAGGING_DATA = psnzBnnrList;
      // 아직 검증안된 모듈배너 E
      const tagging = psnzBnnrList.filter(data => data.tagging === 'E');
      window.$nuxt.$log.log('tagging :', tagging);
      window.$nuxt.$log.log('taggingStop :', this.MD_PSNZ_BNNR_TAGGING_STOP);
      // 태깅여부가 전체 Y면
      if (tagging.length === 0 && !this.MD_PSNZ_BNNR_TAGGING_STOP) {
        window.$nuxt.$adobeUtil?.psnzBnnrTagging(
          psnzBnnrList.filter(data => data.tagging === 'Y').map(data => data.psnzCntsId),
        );
        this.MD_PSNZ_BNNR_TAGGING_STOP = true;
      }
    }
  },
  // 마이데이터 회원 여부 리턴
  async getMdMbYn() {
    const res = await getMemberInfo();
    window.$nuxt.$log.log(`[마이데이터유틸-getMdMbYn] getMdMbYn res::`, res);
    let mdMbYn = 'N'; // 마이데이터 회원여부 (현재 가입중인 회원에 대해 'Y'. 탈회 시, 'N' 셋팅)
    if (res?.success) {
      mdMbYn = res.payload?.mdMbYn || 'N';
    }
    return mdMbYn;
  },
  /**
   * 마이데이터 자산 싱글쿼테이션(') escape 처리를 위한 함수
   * @param {String|Object|Array} data escape 처리할 데이터
   * @return {String|Object|Array} escape 처리 한 데이터 반환
   */
  escapeMyData(data, key = false) {
    return escapeOrUnescape(data, key, true);
  },
  /**
   * escape 처리한 데이터를  unescape 처리를 위한 함수
   * @param {String|Object|Array} data unescape 처리할 데이터
   * @return {String|Object|Array} unescape 처리 한 데이터 반환
   */
  unescapeMyData(data, key = false) {
    return escapeOrUnescape(data, key, false);
  },
  /**
   * 마이데이터 최초 접속여부 확인
   * @param {String} data escape 처리를 위한 Text
   */
  async userExistCheck(queryData) {
    const res = await getUserExistCheck();
    window.$nuxt.$log.log(`[마이데이터유틸-userExistCheck] res ::`, res);

    if (res?.success) {
      const valid = res?.payload?.valid;

      if (!valid) {
        // 최초 접속인 경우 기관연결 추천가이드 화면으로 이동
        this.goToNextPage('/mydata/join/UPFMJO0201M2?rcmdYn=N');
      } else {
        // 최초 접속이 아닌 경우 자산연결 화면으로 이동
        this.goToNextPage('/mydata/join/UPFMJO0201M0', queryData);
      }
    }
  },
};
/**
 * string, object, array 데이터를 escape 또는 unescape 처리하는 함수
 * @param {String|Object|Array} data escape 또는 unescape 처리할 데이터
 * @param {String} key Object, array Object 데이터 입력 시 찾을 key 값
 * @param {Boolean} isEscape escape 또는 unescape 처리 여부
 * @return {String|Object|Array} escape 또는 unescape 처리 한 데이터 반환
 */
const escapeOrUnescape = (data, key, isEscape = true) => {
  window.$nuxt.$log.log('[마이데이터유틸-escapeOrUnescape] data ::', data, '| key ::', key);
  window.$nuxt.$log.log('[마이데이터유틸-escapeOrUnescape] isEscape ::', isEscape ? 'escape' : 'unescape');
  if (data == null) return data;
  // key param 이 없고 type이 string 이면 escape 또는 unescape 처리
  if (!key && typeof data === 'string') {
    window.$nuxt.$log.log('[마이데이터유틸-escapeOrUnescape] key 없음, data String');
    return isEscape ? _.escape(data) : _.unescape(data);
  }

  // key가 있고 들어온 object data에서 찾을 수 있으면 escape 또는 unescape 처리
  if (key && data[key]) {
    const cloneObjectData = Object.assign({}, data);
    window.$nuxt.$log.log('[마이데이터유틸-escapeOrUnescape] object[key] 데이터 있음');
    cloneObjectData[key] = isEscape ? _.escape(cloneObjectData[key]) : _.unescape(cloneObjectData[key]);
    return cloneObjectData;
  }

  // data 가 array 이고 array 에서 해당 key 데이터를 찾을 수 있으면 escape 또는 unescape 처리
  if (key && Array.isArray(data)) {
    window.$nuxt.$log.log('[마이데이터유틸-escapeOrUnescape] data Array 이고 key 있음');
    return data.map(item => {
      const cloneObjectData = Object.assign({}, item);
      if (cloneObjectData[key]) {
        cloneObjectData[key] = isEscape ? _.escape(cloneObjectData[key]) : _.unescape(cloneObjectData[key]);
        return cloneObjectData;
      } else return cloneObjectData;
    });
  }

  // unescape 처리 이고 key가 없거나 들어온 object data 에서 key 를 찾을 수 없으면 unescape 처리
  if (!isEscape && (!key || !Object.keys(data).includes(key))) {
    window.$nuxt.$log.log('[마이데이터유틸-escapeOrUnescape] unescape 이고 object[key] 데이터가 없음');
    return JSON.parse(_.unescape(JSON.stringify(data)));
  } else return data;
};
